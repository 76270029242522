import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listFundingCompanys, userByCompanyGroup } from "../../graphql/queries";
import { Link } from "react-router-dom";
import Title from "../Title";
import { Button } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";
import EditIcon from "@mui/icons-material/Edit";

function List({ match }) {
  const { path } = match;
  const [users, setUsers] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const userCompanyId = localStorage.getItem("companyId");

  useEffect(() => {
    API.graphql(graphqlOperation(listFundingCompanys))
      .then((result) => {
        setFundingCompanies(result.data.listFundingCompanys.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchUsersInGroup();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const fetchUsersInGroup = async () => {
    setFetching(true);
    try {
      const userData = await API.graphql(
        graphqlOperation(userByCompanyGroup, {
          companyId: userCompanyId,
          group: { eq: "fcusers" },
        })
      );
      const userList = userData.data.userByCompanyGroup.items;
      setUsers(userList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Users", error);
    }
    setFetching(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`${path}/edit/${params.value}`}
          className="btn btn-sm btn-primary mr-1"
        >
          <EditIcon></EditIcon>
        </Link>
      ),
    },
    {
      field: "companyId",
      headerName: "Funding Company",
      flex: 1.5,
      editable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div>
            {fundingCompanies.find((fc) => fc.id === params.value).name}
          </div>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      sortable: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      flex: 1,
    },
  ];

  return (
    <React.Fragment>
      <Button
        variant="contained"
        component={Link}
        to={`${path}/add`}
        color="primary"
      >
        Add Member
      </Button>
      <Title>Members</Title>
      {fetching ? (
        <p>Fetching Members..</p>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPro
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={users}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      )}
    </React.Fragment>
  );
}

export { List };
