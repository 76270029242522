import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import { searchIncidents, listFundingCompanys } from "../../graphql/queries";
import { Grid, TextField, Button, Stack } from "@mui/material";
import Title from "../Title";
import moment from "moment";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  gridClasses,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Divider } from "@mui/material";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

function List({ match }) {
  const apiRef = useGridApiRef();
  const history = useHistory();
  const userCompanyId = localStorage.getItem("companyId");
  const { path } = match;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [fetching, setFetching] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [fundingCompanies, setFundingCompanies] = useState([]);

  useEffect(() => {
    fetchFundingCompanies();
  }, []);

  const fetchFundingCompanies = async () => {
    setFetching(true);
    try {
      const fundingCompanyData = await API.graphql(
        graphqlOperation(listFundingCompanys)
      );
      const fundingCompanyList =
        fundingCompanyData.data.listFundingCompanys.items;
      setFundingCompanies(fundingCompanyList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Funding Comapnies", error);
    }
    setFetching(false);
  };

  const onSubmit = async (data) => {
    const filter = {
      or: [
        {
          and: [
            {
              lastName: { match: data.lastName },
              firstName: { match: data.firstName },
              companyId: { eq: userCompanyId },
            },
          ],
        },
      ],
    };

    setFetching(true);
    try {
      const searchData = await API.graphql(
        graphqlOperation(searchIncidents, { filter })
      );
      const searchList = searchData.data.searchIncidents.items;
      setIncidents(searchList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Incidents", error);
    }
    setFetching(false);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    history.push(`/incidents/edit/${id}`);
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "companyId",
      headerName: "Funding Company",
      flex: 1.5,
      editable: false,
      renderCell: (params) => {
        return (
          <>{fundingCompanies.find((fc) => fc.id === params.value).name}</>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: false,
    },
    {
      field: "birthDate",
      headerName: "DOB",
      flex: 1,
      editable: false,
      type: "date",
    },
    {
      field: "incidentDate",
      headerName: "Incident Date",
      sortable: true,
      type: "date",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Modified Date",
      sortable: true,
      type: "date",
      flex: 1,
      renderCell: (params) => {
        return <>{moment(params.value).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      field: "caseType",
      headerName: "Case Type",
      sortable: true,
      flex: 1,
    },
    {
      field: "clientStatus",
      headerName: "Client Status",
      sortable: true,
      flex: 1,
    },
  ];

  return (
    <React.Fragment>
      <Button
        variant="contained"
        component={Link}
        to={`${path}/add`}
        color="primary"
      >
        Add Incident
      </Button>
      <Title>List Incidents</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.firstName}
                  required
                  {...register("firstName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.lastName}
                  required
                  {...register("lastName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            item
          >
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                Search
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Divider orientation="vertical" flexItem />
          </Grid>
        </Grid>
      </form>
      <Grid item container justifyContent="center" alignItems="center">
        {fetching ? (
          <p>Fetching Incidents..</p>
        ) : (
          <div style={{ height: 600, width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGridPro
                  components={{
                    Toolbar: CustomToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  rows={incidents}
                  columns={columns}
                  apiRef={apiRef}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                />
              </div>
            </div>
          </div>
        )}
      </Grid>
    </React.Fragment>
  );
}

export { List };
