import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import {
  searchIncidents,
  listFundingCompanys,
  searchPayoutIncidents,
} from "../../graphql/queries";
import { createPayoutIncident } from "../../graphql/mutations";
import { Grid, TextField, Button, Stack } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Title from "../Title";
import moment from "moment";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  gridClasses,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Modal,
  Typography,
  Divider,
  Box,
  Backdrop,
  Fade,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import PaidIcon from "@mui/icons-material/Paid";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function PrintElem() {
  console.log("inside print");
  var headstr = "<html><head><title>Booking Details</title></head><body>";
  var footstr = "</body>";
  var newstr = document.getElementById("searchPrint").innerHTML;
  var oldstr = document.body.innerHTML;
  document.body.innerHTML = headstr + newstr + footstr;
  window.print();
  document.body.innerHTML = oldstr;
  return false;
}

function Search({ match }) {
  const userDetails = JSON.parse(localStorage.getItem("userAttributes"));
  const userCompanyId = localStorage.getItem("companyId");
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const companyId = watch("companyId", "");

  const [fetching, setFetching] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [incidentDate, setIncidentDate] = useState(null);
  const [incidents, setIncidents] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [payoutOpen, setPayoutOpen] = React.useState(false);
  const handlePayoutOpen = () => setPayoutOpen(true);
  const handlePayoutClose = () => setPayoutOpen(false);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [incidentPaidAlertOpen, setIncidentPaidAlertOpen] = React.useState(
    false
  );
  const [payoffs, setPayoffs] = useState([]);
  const [incidentPayoffs, setIncidentPayoffs] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    fetchFundingCompanies();
  }, []);

  const fetchFundingCompanies = async () => {
    setFetching(true);
    try {
      const fundingCompanyData = await API.graphql(
        graphqlOperation(listFundingCompanys)
      );
      const fundingCompanyList =
        fundingCompanyData.data.listFundingCompanys.items;
      setFundingCompanies(fundingCompanyList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Funding Comapnies", error);
    }
    setFetching(false);
  };

  const fetchPayOffs = async (id) => {
    setFetching(true);
    try {
      const payoffData = await API.graphql(
        graphqlOperation(searchPayoutIncidents, {
          filter: {
            incidentId: { eq: id },
          },
        })
      );
      const payoffList = payoffData.data.searchPayoutIncidents.items;
      setPayoffs(payoffList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Payoffs Requested", error);
    }
    setFetching(false);
  };

  const onSubmit = async (data) => {
    data.birthDate = moment(birthDate).format("MM/DD/YYYY");
    data.incidentDate = moment(incidentDate).format("MM/DD/YYYY");

    const filter = {
      or: [
        {
          and: [
            {
              lastName: { match: data.lastName },
              birthDate: { eq: data.birthDate },
              incidentDate: { eq: data.incidentDate },
            },
          ],
        },
        {
          and: [
            {
              firstName: { match: data.firstName },
              lastName: { match: data.lastName },
            },
          ],
        },
        {
          and: [
            {
              firstName: { match: data.lastName },
              lastName: { match: data.firstName },
            },
          ],
        },
        {
          and: [
            {
              incidentDate: { eq: data.incidentDate },
              birthDate: { eq: data.birthDate },
            },
          ],
        },
        {
          and: [
            {
              lastName: { match: data.lastName },
              birthDate: { eq: data.birthDate },
            },
          ],
        },
        {
          and: [
            {
              lastName: { match: data.lastName },
              incidentDate: { eq: data.incidentDate },
            },
          ],
        },
      ],
    };

    setFetching(true);
    try {
      const searchData = await API.graphql(
        graphqlOperation(searchIncidents, { filter })
      );
      const searchList = searchData.data.searchIncidents.items;
      setIncidents(searchList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Incidents", error);
    }
    setFetching(false);
  };

  const onPayoutSubmit = async (data) => {
    console.log("data", data);
    const incidentId = data.id;
    const fromEmail = userDetails.email;
    const toEmail = data.toEmail;
    const subject = data.subject;
    const body = data.body;
    const companyId = userCompanyId;

    await API.graphql({
      query: createPayoutIncident,
      variables: {
        input: { incidentId, fromEmail, toEmail, subject, body, companyId },
      },
    });

    handlePayoutClose();
  };

  const handlePreviewClick = (id) => (event) => {
    event.stopPropagation();
    const currentIncident = incidents.find((x) => x.id === id);
    const incidentData = currentIncident;
    [
      "companyId",
      "firstName",
      "lastName",
      "middleName",
      "userId",
      "partyType",
      "title",
      "litigationState",
      "clientState",
      "caseType",
      "clientStatus",
      "clientZip",
      "targetDefendant",
      "birthDate",
      "incidentDate",
    ].forEach((fieldName) => {
      setValue(fieldName, incidentData[fieldName]);
    });

    fetchPayOffs(id);

    handleOpen();
  };

  const handleInitiatePayoutClick = (id) => (event) => {
    event.stopPropagation();
    const currentIncident = incidents.find((x) => x.id === id);

    if (currentIncident.companyId === userCompanyId) {
      setAlertOpen(true);
    } else if (currentIncident.clientStatus !== "Active") {
      setIncidentPaidAlertOpen(true);
    } else {
      const currentFundingCompany = fundingCompanies.find(
        (x) => x.id === currentIncident.companyId
      );
      setValue("toEmail", currentFundingCompany.email);
      const incidentData = currentIncident;
      [
        "id",
        "companyId",
        "firstName",
        "lastName",
        "middleName",
        "userId",
        "partyType",
        "title",
        "litigationState",
        "clientState",
        "caseType",
        "clientStatus",
        "clientZip",
        "targetDefendant",
        "caseStatus",
        "birthDate",
        "incidentDate",
      ].forEach((fieldName) => {
        setValue(fieldName, incidentData[fieldName]);
      });
      setValue(
        "subject",
        "Request Buyout - " +
          incidentData.firstName +
          " " +
          incidentData.lastName +
          ", DOB - " +
          incidentData.birthDate +
          ", Incident Date - " +
          incidentData.incidentDate
      );
      handlePayoutOpen();
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: "companyId",
      headerName: "Funding Company",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <>{fundingCompanies.find((fc) => fc.id === params.value).name}</>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      editable: false,
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      width: 120,
      editable: false,
    },
    {
      field: "incidentDate",
      headerName: "Incident Date",
      sortable: true,
      width: 120,
    },
    {
      field: "clientStatus",
      headerName: "Client Status",
      sortable: true,
      width: 120,
    },
    {
      field: "updatedAt",
      headerName: "Modified Date",
      sortable: true,
      type: "date",
      width: 120,
      renderCell: (params) => {
        return <>{moment(params.value).format("YYYY-MM-DD")}</>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      disableExport: true,
      width: 80,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<PreviewIcon />}
            label="Preview"
            onClick={handlePreviewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<PaidIcon />}
            label="Initiate Payout"
            onClick={handleInitiatePayoutClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const payoffColumns = [
    {
      field: "createdAt",
      headerName: "Requested Date",
      sortable: true,
      flex: 1.5,
      renderCell: (params) => {
        return <>{moment(params.value).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      field: "companyId",
      headerName: "Funding Company",
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>{fundingCompanies.find((fc) => fc.id === params.value).name}</>
        );
      },
    },
  ];

  const [sortModel, setSortModel] = React.useState([
    {
      field: "createdAt",
      sort: "asc",
    },
  ]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Collapse in={alertOpen}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            You cannot initiate payout for your own company.
          </Alert>
        </Collapse>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Collapse in={incidentPaidAlertOpen}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIncidentPaidAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            This Incident is not active.
          </Alert>
        </Collapse>
      </Box>
      <Stack direction="row" justifyContent="end">
        <IconButton onClick={PrintElem}>
          <PrintIcon />
        </IconButton>
      </Stack>
      <Title>Search Incidents</Title>
      <div id="searchPrint">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3}></Grid>
            <Grid item xs={3} md={3}>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name(Use With Last Name Only)"
                    fullWidth
                    variant="standard"
                    value={value || ""}
                    error={errors.firstName}
                    {...register("firstName", { required: false })}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    variant="standard"
                    value={value || ""}
                    error={errors.lastName}
                    {...register("lastName", { required: false })}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} md={3}></Grid>
            <Grid item xs={3} md={3}></Grid>
            <Grid item xs={3} md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  autoOk
                  label="Birth Date"
                  inputFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  value={birthDate && Date.parse(birthDate)}
                  onChange={(date) => setBirthDate(date)}
                  renderInput={(params) => (
                    <TextField fullWidth variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  autoOk
                  label="Incident Date"
                  inputFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  value={incidentDate && Date.parse(incidentDate)}
                  onChange={(date) => setIncidentDate(date)}
                  renderInput={(params) => (
                    <TextField fullWidth variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} md={3}></Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              item
            >
              <Stack direction="row" spacing={2}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Divider orientation="vertical" flexItem />
            </Grid>
          </Grid>
        </form>
        <Grid item container justifyContent="center" alignItems="center">
          {fetching ? (
            <p>Fetching Incidents..</p>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
              style={{ width: "85%" }}
            >
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                autoHeight
                rows={incidents}
                columns={columns}
                rowThreshold={0}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                pagination
                disableSelectionOnClick
              />
            </Stack>
          )}
        </Grid>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div>
          <Box sx={style} padding={3}>
            <FormControl
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ margin: "20px" }}
              >
                View Incident
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="companyId">Funding Company</InputLabel>
                    <Select
                      input={<Input id="companyId" />}
                      disabled
                      id="companyId"
                      value={companyId}
                      label="Funding Company"
                      error={errors.companyId}
                    >
                      {fundingCompanies.map((fc) => (
                        <MenuItem key={fc.id} value={fc.id}>
                          {fc.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="firstName"
                        name="firstName"
                        label="Client First Name"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("firstName", { required: true })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="middleName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="middleName"
                        name="middleName"
                        label="Client Middle Name"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("middleName", {
                          required: true,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="lastName"
                        name="lastName"
                        label="Client Last Name"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("lastName", { required: true })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="clientStatus"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="clientStatus"
                        name="clientStatus"
                        label="Client Status"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("clientStatus", {
                          required: true,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="caseType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="caseType"
                        name="caseType"
                        label="Case Type"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("caseType", { required: true })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="birthDate"
                        name="birthDate"
                        label="Birth Date"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("birthDate", { required: true })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="clientState"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="clientState"
                        name="clientState"
                        label="Client State"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("clientState", {
                          required: true,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="clientZip"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="clientZip"
                        name="clientZip"
                        label="Client Zip Code"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("clientZip", { required: true })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="incidentDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="incidentDate"
                        name="incidentDate"
                        label="Incident Date"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("incidentDate", {
                          required: true,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="litigationState"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="litigationState"
                        name="litigationState"
                        label="Litigation State"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("litigationState", {
                          required: true,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="targetDefendant"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        id="targetDefendant"
                        name="targetDefendant"
                        label="Target Defendant"
                        fullWidth
                        variant="standard"
                        value={value || ""}
                        {...register("targetDefendant", {
                          required: true,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>

            <FormControl
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ margin: "20px" }}
              >
                Payoff Requests
              </Typography>
              <div style={{ width: "100%" }}>
                <DataGridPro
                  autoHeight
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  rows={payoffs}
                  columns={payoffColumns}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                  pageSize={10}
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  pagination
                  disableSelectionOnClick
                />
              </div>
            </FormControl>
          </Box>
        </div>
      </Modal>
      <Modal
        open={payoutOpen}
        onClose={handlePayoutClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={payoutOpen}>
          <Box sx={style} padding={3}>
            <React.Fragment>
              <Title>Initiate Payout</Title>
              <form onSubmit={handleSubmit(onPayoutSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={8} shouldHide={true}>
                    <Controller
                      name="id"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled
                          type="hidden"
                          id="id"
                          name="id"
                          fullWidth
                          variant="standard"
                          value={value || ""}
                          error={errors.id}
                          {...register("id", {
                            required: false,
                          })}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name="fromEmail"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled
                          id="fromEmail"
                          name="fromEmail"
                          label="From"
                          fullWidth
                          variant="standard"
                          value={userDetails.email || ""}
                          error={errors.fromEmail}
                          {...register("fromEmail", {
                            required: false,
                          })}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name="toEmail"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled
                          id="toEmail"
                          name="toEmail"
                          label="To"
                          fullWidth
                          variant="standard"
                          value={value || ""}
                          error={errors.toEmail}
                          {...register("toEmail", {
                            required: true,
                          })}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="subject"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          id="subject"
                          name="subject"
                          label="Subject"
                          fullWidth
                          variant="standard"
                          value={value || ""}
                          error={errors.subject}
                          {...register("subject", {
                            required: true,
                          })}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="body"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          id="body"
                          name="body"
                          label="Body (Please Add Your Company ReplyTo Email Below)"
                          multiline
                          rows={4}
                          fullWidth
                          variant="standard"
                          defaultValue={value || ""}
                          error={errors.body}
                          {...register("body", { required: true })}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={2}>
                      <Button type="submit" color="primary" variant="contained">
                        Send
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handlePayoutClose}
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </React.Fragment>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export { Search };
