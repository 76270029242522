import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../Title";
import EditIcon from "@mui/icons-material/Edit";
import { API, graphqlOperation } from "aws-amplify";
import { Button } from "@mui/material";
import { listFundingCompanys } from "../../graphql/queries";
import {
  DataGridPro,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";

function List({ match }) {
  const { path } = match;
  const [fetching, setFetching] = useState(false);
  const [fundingCompanies, setFundingCompanies] = useState([]);

  useEffect(() => {
    fetchFundingCompanies();
  }, []);

  const fetchFundingCompanies = async () => {
    setFetching(true);
    try {
      const fundingCompanyData = await API.graphql(
        graphqlOperation(listFundingCompanys)
      );
      const fundingCompanyList =
        fundingCompanyData.data.listFundingCompanys.items;
      setFundingCompanies(fundingCompanyList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Funding Comapnies", error);
    }
    setFetching(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`${path}/edit/${params.value}`}
          className="btn btn-sm btn-primary mr-1"
        >
          <EditIcon></EditIcon>
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Funding Company Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Pay-off Email",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
  ];

  return (
    <React.Fragment>
      <Button
        variant="contained"
        component={Link}
        to={`${path}/add`}
        color="primary"
      >
        Add Funding Company
      </Button>
      <Title>Funding Companies</Title>
      {fetching ? (
        <p>Fetching Funding Companies...</p>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPro
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={fundingCompanies}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      )}
    </React.Fragment>
  );
}

export { List };
