import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { searchCasetypes } from "../../graphql/queries";
import { Link } from "react-router-dom";
import Title from "../Title";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGridPro,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";

function List({ match }) {
  const { path } = match;
  const [caseTypes, setCaseTypes] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchCaseTypes();
  }, []);

  const fetchCaseTypes = async () => {
    setFetching(true);
    try {
      const caseTypeData = await API.graphql(
        graphqlOperation(searchCasetypes, {
          sort: {
            field: "type",
            direction: "asc",
          },
        })
      );
      const caseTypeList = caseTypeData.data.searchCasetypes.items;
      setCaseTypes(caseTypeList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Case Types", error);
    }
    setFetching(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`${path}/edit/${params.value}`}
          className="btn btn-sm btn-primary mr-1"
        >
          <EditIcon></EditIcon>
        </Link>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      editable: false,
    },
  ];

  const [sortModel, setSortModel] = React.useState([
    {
      field: "type",
      sort: "asc",
    },
  ]);

  return (
    <React.Fragment>
      <Title>Incident Case Types</Title>
      <Button
        variant="contained"
        component={Link}
        to={`${path}/add`}
        color="primary"
      >
        Add Case Type
      </Button>
      {fetching ? (
        <p>Fetching Case Types...</p>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPro
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={caseTypes}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      )}
    </React.Fragment>
  );
}

export { List };
