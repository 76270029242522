import React, { useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listStates, searchCasetypes } from "../../graphql/queries";
import XLSX from "xlsx";
import { process_csv_xls_xlsx_data } from "../../csv-xls-xlsx-upload-features/ProcessData";
import moment from "moment";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { searchIncidents } from "../../graphql/queries";
import { createIncident, updateIncident } from "../../graphql/mutations";
import {
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  enUS,
} from "@mui/x-data-grid-pro";
import {
  Snackbar,
  Alert,
  Box,
  Button,
  Typography,
  Modal,
  Container,
  Link,
} from "@mui/material/";
import { Storage } from "aws-amplify";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";

LicenseInfo.setLicenseKey(
  "b01a2fc9cb52162361edeedcf12c9a49T1JERVI6MzMzOTYsRVhQSVJZPTE2Njk2NTI3NTgwMDAsS0VZVkVSU0lPTj0x"
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledBox = styled(Box)(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
  },
  "& .valid-row": {
    backgroundColor: "#ffffff",
  },
  "& .invalid-row": {
    backgroundColor: "#ba000d",
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

function UploadIncidents() {
  //const classes = useStyles();
  const apiRef = useGridApiRef();
  const userCompanyId = localStorage.getItem("companyId");
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [states, setStates] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [selected, setSelected] = useState([]);

  const clientStatus = [
    "Active",
    "Closed",
    "Paid-Off Attorney",
    "Paid-Off Funder",
    "Write-Off",
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchStates();
    fetchCaseTypes();
    fetchTemplateFile();
  }, []);

  const [s3files, setS3Files] = useState([]);

  async function fetchTemplateFile() {
    const files = await Storage.list("documents/");
    const fileList = [];
    const signedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.size) {
          let possibleFolder = file.key
            .split("/")
            .slice(0, -1)
            .join("/");
          const signedFile = await Storage.get(file.key, { expires: 60 });
          fileList.push({
            url: signedFile,
            fileName: file.key.split("/")[1],
          });
        }
      })
    );
    setS3Files(fileList);
  }

  function error_reload_page() {
    alert("Error: Please select the correct data file.");
    window.location.reload(false);
  }

  const uploadFile = (fileName, file) => {
    console.log("filename", fileName.name);
    console.log("file", file);
    const filetype = fileName.name.split(".")[fileName.name.split.length - 1];
    console.log(filetype);
    Storage.put(`${userCompanyId}_${fileName.name}`, file, {
      level: "private",
      contentType: "text/csv",
    })
      .then((result) =>
        alert(
          `File: ${
            JSON.parse(JSON.stringify(result)).key
          } has been sucessfully uploaded`
        )
      )
      .catch((err) =>
        alert(`Error: ${err} has occured, contact the administrator`)
      );
  };

  const handleFileUpload = (e, param) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {
          type: "binary",
          cellText: false,
          cellDates: true,
          dateNF: "mm/dd/yyyy",
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, {
          header: 1,
          blankrows: false,
          raw: false,
          dateNF: "mm/dd/yyyy",
        });
        uploadFile(file, data);

        processData(data);
      };
      reader.readAsBinaryString(file);
    } catch {
      error_reload_page();
    }
  };

  const processData = (dataString) => {
    try {
      const dataStringLines = dataString.split(/\r\n|\n/);
      const headers = dataStringLines[0].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      const list = process_csv_xls_xlsx_data(dataString);
      const finalData = list.map((obj) => ({
        ...obj,
        isValid: true,
        companyId: userCompanyId,
      }));
      const validData = validateData(finalData);
      setData(finalData);
    } catch {
      error_reload_page();
    }
  };

  const validateData = (finalData) => {
    // const caseTypeValues = caseTypes.map(({ type }) => type);
    // const stateAbbreviations = states.map(({ abbreviation }) => abbreviation);
    const stateAbbreviations = localStorage.getItem("statesList");
    const caseTypeValues = localStorage.getItem("caseTypeList");

    finalData.map((obj) => {
      const isBirthDateValid = moment(obj.birthDate).isValid();
      const isIncidentDateValid = moment(obj.incidentDate).isValid();
      const isClientStateValid = stateAbbreviations.includes(obj.clientState);
      const isLitigationStateValid = stateAbbreviations.includes(
        obj.litigationState
      );
      const isCaseTypeValid = caseTypeValues.includes(obj.caseType);
      const isClientStatusValid = clientStatus.includes(obj.clientStatus);

      if (
        isBirthDateValid &&
        isIncidentDateValid &&
        isClientStateValid &&
        isCaseTypeValid &&
        isClientStatusValid
      ) {
        obj.isValid = true;
      } else {
        obj.isValid = false;
      }
    });
  };

  const fetchStates = async () => {
    setFetching(true);
    try {
      const statesData = await API.graphql(graphqlOperation(listStates));
      const statesList = statesData.data.listStates.items;
      const statesSorted = statesList.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      setStates(statesList);
      localStorage.setItem(
        "statesList",
        statesSorted.map(({ abbreviation }) => abbreviation)
      );
      setFetching(false);
    } catch (error) {
      console.log("Error fetching States", error);
    }
    setFetching(false);
  };

  const fetchCaseTypes = async () => {
    setFetching(true);
    try {
      const caseTypeData = await API.graphql(
        graphqlOperation(searchCasetypes, {
          sort: {
            field: "type",
            direction: "asc",
          },
        })
      );
      const caseTypeList = caseTypeData.data.searchCasetypes.items;
      setCaseTypes(caseTypeList);
      localStorage.setItem(
        "caseTypeList",
        caseTypeList.map(({ type }) => type)
      );
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Case Types", error);
    }
    setFetching(false);
  };

  const handleEdit = useCallback((params, event) => {
    const stateAbbreviations = localStorage.getItem("statesList");
    const caseTypeValues = localStorage.getItem("caseTypeList");
    const isBirthDateValid = moment(
      params.getValue(params.id, "birthDate")
    ).isValid();
    const isIncidentDateValid = moment(
      params.getValue(params.id, "incidentDate"),
      "MM/DD/YYYY"
    ).isValid();
    const isClientStateValid = stateAbbreviations.includes(
      params.getValue(params.id, "clientState")
    );
    const isLitigationStateValid = stateAbbreviations.includes(
      params.getValue(params.id, "litigationState")
    );
    const isCaseTypeValid = caseTypeValues.includes(
      params.getValue(params.id, "caseType")
    );
    const isClientStatusValid = clientStatus.includes(
      params.getValue(params.id, "clientStatus")
    );

    if (
      isBirthDateValid &&
      isIncidentDateValid &&
      isClientStateValid &&
      isCaseTypeValid &&
      isClientStatusValid
    ) {
      apiRef.current.updateRows([{ id: params.id, isValid: true }]);
    } else {
      apiRef.current.updateRows([{ id: params.id, isValid: false }]);
    }
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
      hide: true,
      sortable: false,
      disableExport: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const isValid = params.props.value;
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const isValid = params.props.value.length <= 2;
        return { ...params.props, error: isValid };
      },
    },
    {
      field: "middleName",
      headerName: "Middle Name",
      width: 100,
      editable: true,
    },
    {
      field: "birthDate",
      headerName: "DOB",
      type: "date",
      width: 100,
      editable: true,
      // renderCell: (params) => {
      //   console.log(params.row.birthDate);
      //   return <>{moment.utc(params.row.birthdate).format("L")}</>;
      // },
    },
    {
      field: "clientState",
      headerName: "Client State",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: states.map((elem) => elem.abbreviation),
      preProcessEditCellProps: (params) => {
        const checkState = (arr, usr) =>
          arr.map((u) => u.abbreviation).includes(usr);
        const isValid = checkState(states, params.props.value);
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "clientZip",
      headerName: "Client Zip",
      width: 100,
      editable: true,
    },
    {
      field: "clientStatus",
      headerName: "Client Status",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Active",
        "Closed",
        "Paid-Off Attorney",
        "Paid-Off Funder",
        "Write-Off",
      ],
    },
    {
      field: "incidentDate",
      headerName: "Incident Date",
      type: "date",
      sortable: true,
      width: 100,
      editable: true,
      // renderCell: (params) => {
      //   return <>{moment.utc(params.row.incidentdate).format("L")}</>;
      // },
    },
    {
      field: "litigationState",
      headerName: "Litigation State",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: states.map((elem) => elem.abbreviation),
      preProcessEditCellProps: (params) => {
        const checkState = (arr, usr) =>
          arr.map((u) => u.abbreviation).includes(usr);
        const isValid = checkState(states, params.props.value);
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "targetDefendant",
      headerName: "Target Defendant",
      width: 100,
      editable: true,
    },
    {
      field: "caseType",
      headerName: "Case Type",
      sortable: true,
      width: 100,
      editable: true,
      type: "singleSelect",
      //valueGetter: (p: GridValueGetterParams) => p.value,
      valueOptions: caseTypes.map((elem) => elem.type),
      preProcessEditCellProps: (params) => {
        const checkCaseType = (arr, ct) => arr.map((u) => u.type).includes(ct);
        console.log(
          "checkCaseType",
          checkCaseType(caseTypes, params.props.value)
        );
        const isValid = checkCaseType(caseTypes, params.props.value);
        console.log("isValid", isValid);
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "isValid",
      headerName: "isValid",
      width: 75,
      hide: true,
      sortable: false,
      disableExport: true,
    },
  ];

  const handleCreateIncident = () => {
    const getRowsMap = apiRef.current.getSelectedRows();
    let getRows = Array.from(getRowsMap.values());

    getRows.map(async (row) => {
      // The new value entered
      const companyId = row.companyId;
      const firstName = row.firstName;
      const lastName = row.lastName;
      const middleName = row.middleName;
      const birthDate = moment.utc(row.birthDate).format("MM/DD/YYYY");
      console.log(birthDate);
      const clientState = row.clientState;
      const clientZip = row.clientZip;
      const clientStatus = row.clientStatus;
      const incidentDate = moment.utc(row.incidentDate).format("MM/DD/YYYY");
      console.log(incidentDate);
      const litigationState = row.litigationState;
      const targetDefendant = row.targetDefendant;
      const caseType = row.caseType;

      const filter = {
        companyId: { eq: companyId },
        firstName: { eq: firstName },
        lastName: { eq: lastName },
        birthDate: { eq: birthDate },
        incidentDate: { eq: incidentDate },
        caseType: { eq: caseType },
      };

      setFetching(true);
      try {
        const searchData = await API.graphql(
          graphqlOperation(searchIncidents, { filter })
        );

        const searchList = searchData.data.searchIncidents.items;
        console.log("searchList", searchList);
        setFetching(false);

        if (searchList.length === 0) {
          try {
            await API.graphql({
              query: createIncident,
              variables: {
                input: {
                  companyId,
                  firstName,
                  lastName,
                  middleName,
                  birthDate,
                  clientState,
                  clientZip,
                  clientStatus,
                  incidentDate,
                  litigationState,
                  targetDefendant,
                  caseType,
                },
              },
            });
            setSnackbar({
              children: "Incident(s) successfully saved",
              severity: "success",
            });
          } catch (error) {
            console.log("Error", error);
            setSnackbar({
              children: "Error while saving Incident(s)",
              severity: "error",
            });
          }
        } else {
          try {
            const id = searchList[0].id;

            await API.graphql({
              query: updateIncident,
              variables: {
                input: {
                  id,
                  companyId,
                  firstName,
                  lastName,
                  middleName,
                  birthDate,
                  clientState,
                  clientZip,
                  clientStatus,
                  incidentDate,
                  litigationState,
                  targetDefendant,
                  caseType,
                },
              },
            });
            setSnackbar({
              children: "Incident(s) successfully Updated",
              severity: "success",
            });
          } catch (error) {
            console.log("Error", error);
            setSnackbar({
              children: "Error while updating Incident(s)",
              severity: "error",
            });
          }
        }
      } catch (error) {
        console.log("Error Fetching Incidents", error);
      }
    });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" onClick={handleCreateIncident}>
          Click Here To Upload
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const CaseTypeCustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const caseTypecolumns = [
    {
      field: "type",
      headerName: "Case Type",
      flex: 1,
      editable: false,
    },
  ];

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            {fetching ? (
              <p>Fetching Case Types...</p>
            ) : (
              <div style={{ height: 600, width: "100%" }}>
                <DataGridPro
                  rows={caseTypes}
                  columns={caseTypecolumns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                  components={{
                    Toolbar: CaseTypeCustomToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </div>
            )}
          </Box>
        </Container>
      </Modal>

      <div style={{ width: "100%" }}>
        <Typography>
          {s3files.map((file) => (
            <div>
              ** Upload Incidents Template File - Download&nbsp;
              <Link target="_blank" rel="noopener" href={file.url}>
                Here
              </Link>
            </div>
          ))}
        </Typography>
        <Typography>
          ** <Button onClick={handleOpen}>Click Here</Button> to view the list
          of Case Types
        </Typography>
        <Typography>
          ** Do not load individual fundings, there should only be one row per
          client/incident date
        </Typography>
        <Typography>
          ** Include Suffix in lastName. Beware of excess verbiage in Last Name
          as it is used for search
        </Typography>
        <Typography>
          ** When you do not have a State Abbreviation use XX (Not Provided will
          display)
        </Typography>
        <Typography>
          ** Valid value for Client Status - Active, Closed, Paid-Off Attorney,
          Paid-Off Funder, Write-Off
        </Typography>
        <Typography>
          ** Valid Date Format to include in the template file - 04/26/1991
          (mm/dd/yyyy)
        </Typography>
        <Typography>
          ** Valid value for ID - any unique value for record (used only for
          importing and validation)
        </Typography>
        <Typography>
          ** Optional fields: middleName,clientZip,litigationState,and
          targetDefendant
        </Typography>
        <br />
        <div className="file-upload">
          <input
            data-testid="input-1"
            id="upload-box"
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={handleFileUpload}
          />
        </div>
        <br></br>
        <StyledBox>
          <DataGridPro
            autoHeight
            disableSelectionOnClick
            apiRef={apiRef}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={data}
            columns={columns}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
              },
            }}
            checkboxSelection
            editMode="row"
            onRowEditStop={handleEdit}
            onSelectionModelChange={(e) => setSelected(e)}
            localeText={enUS.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) => {
              let isValid = params.getValue(params.id, "isValid");
              let appendClass;
              if (isValid === true) appendClass = "valid-row";
              else appendClass = "invalid-row";
              return appendClass;
            }}
            sortModel={[
              {
                field: "id",
                sort: "asc",
              },
            ]}
          />
        </StyledBox>

        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
    </React.Fragment>
  );
}

export { UploadIncidents };
