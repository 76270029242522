import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { createState, updateState } from "../../graphql/mutations";
import { getState } from "../../graphql/queries";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Title from "../Title";

function AddEdit({ history, match }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const riskColorCode = watch("riskColorCode", "");

  const { id } = match.params;
  const isAddMode = !id;

  useEffect(() => {
    const fetchState = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getState, { id: id })
        );
        const stateData = result.data.getState;
        [
          "name",
          "abbreviation",
          "statuteLink",
          "geoValue",
          "riskColorCode",
          "legislativeAction",
          "lr",
          "rateCap",
          "misc",
          "enactedLR",
        ].forEach((fieldName) => {
          setValue(fieldName, stateData[fieldName]);
        });
      } catch (error) {
        console.log("Error Fetching States", error);
      }
    };
    if (id) {
      fetchState();
    }
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      data.id = id;
      await API.graphql({
        query: updateState,
        variables: { input: data },
      });
    } else {
      await API.graphql({
        query: createState,
        variables: { input: data },
      });
    }
    window.location.replace("/states");
  };

  return (
    <React.Fragment>
      <Title>{isAddMode ? "Add US State" : "Edit US State"}</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="name"
                  name="name"
                  label="US State"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.name}
                  {...register("name", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="abbreviation"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="abbreviation"
                  name="abbreviation"
                  label="US State Abbreviation"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.abbreviation}
                  {...register("abbreviation", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="enactedLR"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="enactedLR"
                  name="enactedLR"
                  label="Enacted Legislation and Regulation"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.enactedLR}
                  {...register("enactedLR", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="statuteLink"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="statuteLink"
                  name="statuteLink"
                  label="Statute Link"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.statuteLink}
                  {...register("statuteLink", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="legislativeAction"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="legislativeAction"
                  name="legislativeAction"
                  label="Legislative Action"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.legislativeAction}
                  {...register("legislativeAction", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="geoValue"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled
                  id="geoValue"
                  name="geoValue"
                  label="Geo Value(Do Not Edit)"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.geoValue}
                  {...register("geoValue", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <InputLabel htmlFor="riskColorCode">Funding Risk</InputLabel>
              <Select
                input={<Input id="riskColorCode" />}
                required
                id="riskColorCode"
                value={riskColorCode}
                label="Funding Risk"
                error={errors.riskColorCode}
                {...register("riskColorCode", { required: true })}
              >
                <MenuItem value="1">Green</MenuItem>
                <MenuItem value="2">Yellow</MenuItem>
                <MenuItem value="3">Red</MenuItem>
                <MenuItem value="4">White</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="lr"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="lr"
                  name="lr"
                  label="License(L)/Registration(R)"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.lr}
                  {...register("lr", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="rateCap"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="rateCap"
                  name="rateCap"
                  label="Rate Cap"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.rateCap}
                  {...register("rateCap", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="misc"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="misc"
                  name="misc"
                  label="Misc."
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.misc}
                  {...register("misc", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
