import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { API, graphqlOperation } from "aws-amplify";
import {
  createFundingCompany,
  updateFundingCompany,
} from "../../graphql/mutations";
import { getFundingCompany } from "../../graphql/queries";
import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Title from "../Title";
import { Storage } from "aws-amplify";
import XLSX from "xlsx";

function AddEdit({ history, match }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const status = watch("status", "");

  const { id } = match.params;
  const isAddMode = !id;

  const onSubmit = async (data) => {
    if (id) {
      data.id = id;
      await API.graphql({
        query: updateFundingCompany,
        variables: { input: data },
      });
    } else {
      await API.graphql({
        query: createFundingCompany,
        variables: { input: data },
      });
    }
    window.location.replace("/fundingcompany");
  };

  useEffect(() => {
    const fetchFundingCompanies = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getFundingCompany, { id: id })
        );
        const fundingCompanyData = result.data.getFundingCompany;
        ["name", "email", "status", "allowedDomains"].forEach((fieldName) => {
          setValue(fieldName, fundingCompanyData[fieldName]);
        });
      } catch (error) {
        console.log("Error Fetching Funding Companies", error);
      }
    };
    if (id) {
      fetchFundingCompanies();
    }
  }, [id]);

  function error_reload_page() {
    alert("Error: Please select the correct data file.");
    window.location.reload(false);
  }

  const handleFileUpload = (e, param) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {
          type: "binary",
          cellText: false,
          cellDates: true,
          dateNF: "yyyy-mm-dd",
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, {
          header: 1,
          blankrows: false,
          raw: false,
          dateNF: "yyyy-mm-dd",
        });
        uploadFile(file, data);
      };
      reader.readAsBinaryString(file);
    } catch {
      error_reload_page();
    }
  };

  const uploadFile = (fileName, file) => {
    const filetype = fileName.name.split(".")[fileName.name.split.length - 1];
    Storage.put(`${fileName.name}`, file, {
      level: "private",
      contentType: "text/csv",
      customPrefix: {
        private: `private/funding-companies/${id}/`,
      },
    })
      .then((result) =>
        alert(
          `File: ${
            JSON.parse(JSON.stringify(result)).key
          } has been sucessfully uploaded`
        )
      )
      .catch((err) =>
        alert(`Error: ${err} has occured, contact the administrator`)
      );
  };

  return (
    <React.Fragment>
      <Title>
        {isAddMode ? "Add Funding Company" : "Edit Funding Company"}
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            {isAddMode ? (
              <div></div>
            ) : (
              <div className="file-upload">
                <input
                  data-testid="input-1"
                  id="upload-box"
                  type="file"
                  accept=".csv,.xlsx,.xls"
                  onChange={handleFileUpload}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Funding Company Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.name}
                  {...register("name", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                input={<Input id="status" />}
                required
                id="status"
                value={status}
                label="Funding Company Status"
                error={errors.status}
                {...register("status", { required: true })}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
                <MenuItem value="Suspended">Suspended</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Pay-off Email Address"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.email}
                  {...register("email", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="allowedDomains"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="allowedDomains"
                  name="allowedDomains"
                  label="Allowed Domains For Email"
                  placeholder="domain-1.com, domain-2.com"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.allowedDomains}
                  {...register("allowedDomains", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
