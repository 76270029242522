import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listStates } from "../../graphql/queries";
import { Link } from "react-router-dom";
import Title from "../Title";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Modal,
  TextField,
  InputLabel,
  Box,
  FormControl,
  Typography,
  Grid,
  Select,
  Input,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";
import PreviewIcon from "@mui/icons-material/Preview";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function List({ match }) {
  const history = useHistory();
  const { path } = match;
  const [states, setStates] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  var riskColorCodes = [
    { id: "1", color: "Green" },
    { id: "2", color: "Yellow" },
    { id: "3", color: "Red" },
    { id: "4", color: "White" },
  ];

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const riskColorCode = watch("riskColorCode", "");

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setFetching(true);
    try {
      const statesData = await API.graphql(graphqlOperation(listStates));
      const statesList = statesData.data.listStates.items;
      setStates(statesList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching States", error);
    }
    setFetching(false);
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    history.push(`/states/edit/${id}`);
  };

  const handlePreviewClick = (id) => (event) => {
    event.stopPropagation();

    const currentState = states.find((x) => x.id === id);
    const stateData = currentState;
    [
      "name",
      "abbreviation",
      "statuteLink",
      "geoValue",
      "riskColorCode",
      "legislativeAction",
      "lr",
      "rateCap",
      "misc",
      "enactedLR",
    ].forEach((fieldName) => {
      setValue(fieldName, stateData[fieldName]);
    });

    handleOpen();
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "Actions",
    //   flex: 1,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <Link
    //       to={`${path}/edit/${params.value}`}
    //       className="btn btn-sm btn-primary mr-1"
    //     >
    //       <EditIcon></EditIcon>
    //     </Link>
    //   ),
    // },
    {
      field: "id",
      type: "actions",
      headerName: "Actions",
      disableExport: true,
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<PreviewIcon />}
            label="Preview"
            onClick={handlePreviewClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "abbreviation",
      headerName: "Abbreviation",
      flex: 1,
      editable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
  ];

  const [sortModel, setSortModel] = React.useState([
    {
      field: "abbreviation",
      sort: "asc",
    },
  ]);

  return (
    <React.Fragment>
      <Title>US States</Title>
      <Button
        variant="contained"
        component={Link}
        to={`${path}/add`}
        color="primary"
      >
        Add State
      </Button>
      {fetching ? (
        <p>Fetching States...</p>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPro
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={states}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
          <Modal open={open} onClose={handleClose}>
            <div>
              <Box sx={style} padding={3}>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                    style={{ margin: "20px" }}
                  >
                    View State Details
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="name"
                            name="name"
                            label="US State"
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.name}
                            {...register("name", { required: true })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="abbreviation"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="abbreviation"
                            name="abbreviation"
                            label="US State Abbreviation"
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.abbreviation}
                            {...register("abbreviation", { required: true })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="enactedLR"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="enactedLR"
                            disabled
                            name="enactedLR"
                            label="Enacted Legislation and Regulation"
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.enactedLR}
                            {...register("enactedLR", { required: false })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="statuteLink"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="statuteLink"
                            name="statuteLink"
                            label="Statute Link"
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.statuteLink}
                            {...register("statuteLink", { required: false })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="legislativeAction"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="legislativeAction"
                            name="legislativeAction"
                            label="Legislative Action"
                            fullWidth
                            disabled
                            variant="standard"
                            value={value || ""}
                            error={errors.legislativeAction}
                            {...register("legislativeAction", {
                              required: false,
                            })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="riskColorCode">Funding Risk</InputLabel>
                        <Select
                          input={<Input id="riskColorCode" />}
                          disabled
                          id="riskColorCode"
                          value={riskColorCode}
                          label="Funding Risk"
                          error={errors.riskColorCode}
                        >
                          {riskColorCodes.map((fc) => (
                            <MenuItem key={fc.id} value={fc.id}>
                              {fc.color}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="lr"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="lr"
                            name="lr"
                            label="License(L)/Registration(R)"
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.lr}
                            {...register("lr", { required: false })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="rateCap"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="rateCap"
                            name="rateCap"
                            label="Rate Cap"
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.rateCap}
                            {...register("rateCap", { required: false })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="misc"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="misc"
                            name="misc"
                            label="Misc."
                            fullWidth
                            variant="standard"
                            value={value || ""}
                            error={errors.misc}
                            {...register("misc", { required: false })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            </div>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
}

export { List };
