import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import Title from "../Title";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  getUser,
  listFundingCompanys,
  getFundingCompany,
  userByName,
} from "../../graphql/queries";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PhoneNumberInput } from "../Utilities/PhoneNumberInput";
import generator from "generate-password";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import EmailAutoComplete from "react-autocomplete-email";
import { createUser, updateUser } from "../../graphql/mutations";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({}));

let AWS = require("aws-sdk");

// Initialize the Amazon Cognito credentials provider.
AWS.config.update({
  region: "us-east-2", // Region
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-2:df665cb8-0cca-4420-a7e9-e6301aa2619a",
  }),
});

let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

function AddEdit({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;
  const userCompanyId = localStorage.getItem("companyId");
  const userGroup = localStorage.getItem("userGroup");
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [allowedDomains, setAllowedDomains] = useState([]);

  const customDomains = [allowedDomains];

  var regp = new RegExp("^[a-zA-Z0-9._-]+@" + allowedDomains + "$");

  const Schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Email is invalid")
      .matches(regp, "Email must match company domain"),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });
  const companyId = watch("companyId", "");
  const status = watch("status", "");
  const addAlert = watch("addAlert", "");

  const [email, setEmail] = useState("");
  const emailAutoCompleteRef = useRef();

  useEffect(() => {
    fetchFundingCompanies();
    getFundingCompanyById();
    if (!id) {
      setValue("companyId", userCompanyId);
    }
  }, [id]);

  const fetchFundingCompanies = async () => {
    setFetching(true);
    try {
      const fundingCompanyData = await API.graphql(
        graphqlOperation(listFundingCompanys)
      );
      const fundingCompanyList =
        fundingCompanyData.data.listFundingCompanys.items;
      setFundingCompanies(fundingCompanyList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Funding Companies", error);
    }
    setFetching(false);
  };

  const getFundingCompanyById = async () => {
    setFetching(true);
    try {
      const result = await API.graphql(
        graphqlOperation(getFundingCompany, {
          id: userCompanyId,
        })
      );
      const fundingCompanyData = result.data.getFundingCompany;
      [("name", "email", "status", "allowedDomains")].forEach((fieldName) => {
        setValue(fieldName, fundingCompanyData[fieldName]);
      });
      setAllowedDomains(fundingCompanyData["allowedDomains"]);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Funding Comapnies", error);
    }
    setFetching(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await API.graphql(graphqlOperation(getUser, { id: id }));
        console.log(result.data.getUser);
        const userData = result.data.getUser;
        [
          "id",
          "userName",
          "email",
          "password",
          "firstName",
          "lastName",
          "companyId",
          "group",
          "phone",
          "status",
          "addAlert",
        ].forEach((fieldName) => {
          setValue(fieldName, userData[fieldName]);
        });

        const fundingCompany = await API.graphql(
          graphqlOperation(getFundingCompany, {
            id: userData["companyId"],
          })
        );
        const fundingCompanyData = fundingCompany.data.getFundingCompany;
        console.log(fundingCompanyData);
        setAllowedDomains(fundingCompanyData["allowedDomains"]);
      } catch (error) {
        console.log("Error Fetching User", error);
      }
    };
    if (id) {
      fetchUser();
    }
  }, [id]);

  const password = generator.generate({
    length: 10,
    numbers: true,
    symbols: true,
    uppercase: true,
    lowercase: true,
    strict: true,
  });

  const onSubmit = async (data) => {
    data.group = "fcusers";
    console.log("Data", data);
    setFetching(true);
    var companyId = data.companyId;
    var userName = data.email;
    var firstName = data.firstName;
    var lastName = data.lastName;
    var email = data.email;
    var password = data.password;
    var phone = data.phone;
    var status = data.status;
    var group = data.group;
    var addAlert = data.addAlert;

    try {
      const searchData = await API.graphql(
        graphqlOperation(userByName, { userName: data.email })
      );

      const searchList = searchData.data.userByName.items;
      console.log("searchList", searchList);
      setFetching(false);

      if (searchList.length === 0) {
        let result = new Promise((resolve, reject) => {
          let params = {
            UserPoolId: "us-east-2_VJ9i8FpnG",
            Username: data.email,
            TemporaryPassword: data.password,
            DesiredDeliveryMediums: ["EMAIL"],
            UserAttributes: [
              {
                Name: "phone_number",
                Value: data.phone,
              },
              {
                Name: "email",
                Value: data.email,
              },
              {
                Name: "family_name",
                Value: data.lastName,
              },
              {
                Name: "name",
                Value: data.firstName,
              },
              {
                Name: "custom:status",
                Value: data.status,
              },
              {
                Name: "custom:companyId",
                Value: data.companyId,
              },
              {
                Name: "custom:addAlert",
                Value: data.addAlert,
              },
            ],
          };
          const paramsToGroup = {
            GroupName: "fcusers",
            UserPoolId: "us-east-2_VJ9i8FpnG",
            Username: data.email,
          };
          cognitoIdentityServiceProvider.adminCreateUser(params, function(
            err,
            data
          ) {
            if (err) {
              console.log(err, err.stack);
              alert(err.message);
            } else {
              cognitoIdentityServiceProvider.adminAddUserToGroup(
                paramsToGroup,
                function(err, data) {
                  if (err) {
                    console.log(err, err.stack);
                    alert(err.message);
                  } else {
                    alert("User successfully added to group.");
                    window.location.replace("/members");
                  }
                }
              );
            }
          });
        });

        await API.graphql({
          query: createUser,
          variables: {
            input: {
              companyId,
              userName,
              firstName,
              lastName,
              email,
              password,
              phone,
              status,
              group,
              addAlert,
            },
          },
        });
      } else {
        if (isAddMode) {
          alert("User already exists.");
        } else {
          console.log("EditMode Data", data);

          var id = data.id;

          await API.graphql({
            query: updateUser,
            variables: {
              input: {
                id,
                companyId,
                userName,
                firstName,
                lastName,
                email,
                password,
                phone,
                status,
                group,
                addAlert,
              },
            },
          });

          let userStatusParam = {
            UserPoolId: "us-east-2_VJ9i8FpnG",
            Username: data.email,
          };

          let params = {
            UserPoolId: "us-east-2_VJ9i8FpnG",
            Username: data.email,
            UserAttributes: [
              {
                Name: "phone_number",
                Value: data.phone,
              },
              {
                Name: "email",
                Value: data.email,
              },
              {
                Name: "family_name",
                Value: data.lastName,
              },
              {
                Name: "name",
                Value: data.firstName,
              },
              {
                Name: "custom:status",
                Value: data.status,
              },
              {
                Name: "custom:addAlert",
                Value: data.addAlert.toString(),
              },
            ],
          };

          if (data.status !== "Active") {
            let result = new Promise((resolve, reject) => {
              cognitoIdentityServiceProvider.adminDisableUser(
                userStatusParam,
                function(err, data) {
                  if (err) {
                    console.log(err, err.stack);
                    alert(err.message);
                  } else {
                    alert("User Suspended");
                    window.location.replace("/members");
                  }
                }
              );
            });
          } else {
            let result = new Promise((resolve, reject) => {
              cognitoIdentityServiceProvider.adminUpdateUserAttributes(
                params,
                function(err, data) {
                  if (err) {
                    console.log(err, err.stack);
                    alert(err.message);
                  } else {
                    cognitoIdentityServiceProvider.adminEnableUser(
                      userStatusParam,
                      function(err, data) {
                        if (err) {
                          console.log(err, err.stack);
                          alert(err.message);
                        } else {
                          alert("User details updated.");
                          window.location.replace("/members");
                        }
                      }
                    );
                  }
                }
              );
            });
          }
        }
      }
    } catch (error) {
      console.log("Error Fetching Users", error);
    }
    setFetching(false);
  };

  const handleChange = (e) => {
    setValue("companyId", e.target.value);
    setAllowedDomains(
      fundingCompanies.find((fc) => fc.id === e.target.value).allowedDomains
    );
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>{isAddMode ? "Add Member" : "Modify Member"}</Title>
      <Typography>** Please use your email as your username</Typography>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {isAddMode && userGroup === "admin" ? (
              <FormControl fullWidth className={classes.formControl}>
                <Controller
                  control={control}
                  name="companyId"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      labelId="companyId-label"
                      id="companyId"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      selected={companyId}
                      name="companyId"
                    >
                      {fundingCompanies.map((fc) => (
                        <MenuItem key={fc.id} value={fc.id}>
                          {fc.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            ) : (
              <FormControl fullWidth className={classes.formControl}>
                <Controller
                  control={control}
                  name="companyId"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      disabled
                      labelId="companyId-label"
                      id="companyId"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={companyId}
                      name="companyId"
                    >
                      {fundingCompanies.map((fc) => (
                        <MenuItem key={fc.id} value={fc.id}>
                          {fc.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            {isAddMode ? (
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <EmailAutoComplete
                      ref={emailAutoCompleteRef}
                      onCompletion={(val) => setEmail(val)}
                      domains={customDomains}
                    >
                      <TextField
                        name="email"
                        placeholder={allowedDomains}
                        defaultValue={email}
                        fullWidth
                        label="Email"
                        variant="standard"
                        error={errors.email}
                        {...register("email", { required: true })}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          emailAutoCompleteRef.current.change(e);
                        }}
                        onKeyDown={(e) => emailAutoCompleteRef.current.check(e)}
                      />
                    </EmailAutoComplete>
                  )}
                />
                <div className="error-msg">
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
              </div>
            ) : (
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <EmailAutoComplete
                      ref={emailAutoCompleteRef}
                      onCompletion={(val) => setEmail(val)}
                      domains={customDomains}
                    >
                      <TextField
                        name="email"
                        disabled
                        placeholder={allowedDomains}
                        defaultValue={email}
                        fullWidth
                        variant="standard"
                        error={errors.email}
                        {...register("email", { required: true })}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          emailAutoCompleteRef.current.change(e);
                        }}
                        onKeyDown={(e) => emailAutoCompleteRef.current.check(e)}
                      />
                    </EmailAutoComplete>
                  )}
                />
                <div className="error-msg">
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            {isAddMode ? (
              <Controller
                name="password"
                control={control}
                defaultValue={password}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    disabled
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    value={value || ""}
                    error={errors.password}
                    {...register("password", { required: true })}
                    onChange={onChange}
                  />
                )}
              />
            ) : (
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    disabled
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    value={value || ""}
                    error={errors.password}
                    {...register("password", { required: true })}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.firstName}
                  {...register("firstName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.lastName}
                  {...register("lastName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  required
                  value={value}
                  onChange={onChange}
                  defaultCountry="US"
                  id="phone"
                  inputComponent={PhoneNumberInput}
                />
              )}
            />
            {errors["phone"] && (
              <p className="error-message">Invalid Phone Number</p>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="status">User Status</InputLabel>
              <Select
                input={<Input id="status" />}
                labelId="status"
                required
                id="status"
                value={status}
                label="Status"
                error={errors.status}
                {...register("status", { required: true })}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="addAlert">
                Allowed To Add Possible Fraud Alert?
              </InputLabel>
              <Select
                input={<Input id="addAlert" />}
                labelId="addAlert"
                required
                id="addAlert"
                value={addAlert}
                label="Allowed To Add Possible Fraud Alert?"
                error={errors.addAlert}
                {...register("addAlert", { required: true })}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container justifyContent="center" alignItems="center">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Button type="submit" variant="contained">
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
