import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listStates } from "../graphql/queries";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import { scaleThreshold, scaleQuantize } from "d3-scale";
import { Modal, Box, Link } from "@mui/material";
import allStates from "./data/allstates.json";
import Title from "./Title";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const colorScale = scaleQuantize()
  .domain([1, 4])
  .range(["green", "yellow", "red", "white"]);

const MapChart = ({ setTooltipContent }) => {
  const [states, setStates] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [content, setContent] = useState("");
  const [rateCap, setRateCap] = useState("");
  const [legislativeAction, setLegislativeAction] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [licenseRegistration, setLicenseRegistration] = useState("");
  const [riskColor, setRiskColor] = useState("");
  const [misc, setMisc] = useState("");

  var riskColorCodes = [
    { id: "1", color: "Green" },
    { id: "2", color: "Yellow" },
    { id: "3", color: "Red" },
    { id: "4", color: "White" },
  ];

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setFetching(true);
    try {
      const statesData = await API.graphql(graphqlOperation(listStates));
      const statesList = statesData.data.listStates.items;
      setStates(statesList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching States", error);
    }
    setFetching(false);
  };

  const handleStateClick = ({ id, val }) => {
    const stateData = states.find((stateData) => {
      if (stateData.abbreviation === id) {
        return true;
      } else {
        return false;
      }
    });

    setSelectedState(stateData.name);
    const sLink = `${stateData.statuteLink ? stateData.statuteLink : ""}`;
    setContent(sLink);
    const lAction = `${
      stateData.legislativeAction ? stateData.legislativeAction : ""
    }`;
    setLegislativeAction(lAction);
    const rCap = `${stateData.rateCap ? stateData.rateCap : ""}`;
    setRateCap(rCap);
    const lr = `${stateData.lr ? stateData.lr : ""}`;
    setLicenseRegistration(lr);
    const colorCode = `${
      stateData.riskColorCode ? stateData.riskColorCode : ""
    }`;
    console.log(colorCode);
    const colorValue = riskColorCodes.find((c) => c.id === colorCode);
    console.log(colorValue.color);
    setRiskColor(colorValue.color);
    const miscData = `${stateData.misc ? stateData.misc : ""}`;
    setMisc(miscData);

    handleOpen();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div>
          <Box sx={style} padding={3}>
            <Title>State - {selectedState}</Title>
            <br />
            Statue Link -{" "}
            <Link target="_blank" rel="noopener" href={content}>
              {content}
            </Link>
            <br />
            Legislative Action -
            <Link target="_blank" rel="noopener" href={legislativeAction}>
              {legislativeAction}
            </Link>
            <br />
            Rate Cap - {rateCap}
            <br />
            License(L)/Registration(R) - {licenseRegistration}
            <br />
            Funding Risk - {riskColor}
            <br />
            Misc. - {misc}
          </Box>
        </div>
      </Modal>
      <ComposableMap
        projection="geoAlbersUsa"
        data-tip=""
        style={{ height: "auto", margin: "0, 10%,0,10%" }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => {
                const cur = states.find((s) => s.geoValue === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#000"
                    geography={geo}
                    fill={colorScale(cur ? cur.riskColorCode : "#DDD")}
                    style={{
                      hover: {
                        fill: "#F53",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none",
                      },
                    }}
                  />
                );
              })}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find((s) => s.val === geo.id);
                return (
                  <g
                    key={geo.rsmKey + "-name"}
                    style={{ cursor: "pointer" }}
                    onClick={(event) => handleStateClick(cur)}
                  >
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text y="2" fontSize={14} textAnchor="middle">
                            {cur.id}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id][0]}
                          dy={offsets[cur.id][1]}
                        >
                          <text x={4} fontSize={14} alignmentBaseline="middle">
                            {cur.id}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default MapChart;
