import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../Title";
import EditIcon from "@mui/icons-material/Edit";
import { API, graphqlOperation } from "aws-amplify";
import { Button } from "@mui/material";
import {
  listCompanyKeys,
  listFundingCompanys,
  keyByCompany,
} from "../../graphql/queries";
import {
  DataGridPro,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";
import moment from "moment";

function List({ match }) {
  const { path } = match;
  const [fetching, setFetching] = useState(false);
  const [companyApiKeys, setCompanyApiKeys] = useState([]);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const userCompanyId = localStorage.getItem("companyId");

  useEffect(() => {
    const timer = setTimeout(() => {
      API.graphql(graphqlOperation(listFundingCompanys))
        .then((result) => {
          fetchCompanyKeys();
          setFundingCompanies(result.data.listFundingCompanys.items);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   fetchCompanyKeys();
  // }, []);

  const filter = {
    companyId: { eq: userCompanyId },
  };

  const fetchCompanyKeys = async () => {
    setFetching(true);
    try {
      const companyKeyData = await API.graphql(
        graphqlOperation(listCompanyKeys, { filter: filter })
      );
      const companyKeyList = companyKeyData.data.listCompanyKeys.items;
      console.log(companyKeyList);
      setCompanyApiKeys(companyKeyList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Company Keys", error);
    }
    setFetching(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`${path}/edit/${params.value}`}
          className="btn btn-sm btn-primary mr-1"
        >
          <EditIcon></EditIcon>
        </Link>
      ),
    },
    {
      field: "companyId",
      headerName: "Funding Company",
      flex: 1.5,
      editable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div>
            {fundingCompanies.find((fc) => fc.id === params.value).name}
          </div>
        );
      },
    },
    {
      field: "keyId",
      headerName: "Key Id",
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Key Description",
      flex: 1,
      editable: false,
    },
    {
      field: "isProd",
      headerName: "Is Prod?",
      flex: 1,
      editable: false,
    },
    {
      field: "reminderEmail",
      headerName: "Reminder Email",
      flex: 1,
      editable: false,
    },
    {
      field: "expires",
      headerName: "Expires",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <>{moment.unix(params.value).format("LLLL")}</>;
      },
    },
  ];

  return (
    <React.Fragment>
      <Button
        variant="contained"
        component={Link}
        to={`${path}/add`}
        color="primary"
      >
        Create API Keys
      </Button>
      <Title>API Keys</Title>
      {fetching ? (
        <p>Fetching Keys...</p>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPro
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={companyApiKeys}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      )}
    </React.Fragment>
  );
}

export { List };
