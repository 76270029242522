import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "./aws-exports";
import { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Header from "./layout/Header";
import { Provider } from "react-redux";
import store from "./store/";
import { Home } from "./components/Home";
import { FundingCompany } from "./components/FundingCompany/Index";
import { FraudAlert } from "./components/FraudAlerts/Index";
import { Incident } from "./components/Incidents/Index";
import { CaseType } from "./components/CaseType/Index";
import { States } from "./components/States/Index";
import { Users } from "./components/Members/Index";
import { FCAdmin } from "./components/FCAdmin/Index";
import { SearchIncidents } from "./components/Incidents/SearchIndex";
import { UploadIncidents } from "./components/Incidents/UploadIncidents";
import { ViewPayoff } from "./components/Incidents/ViewPayoff";
import { MembersAdmin } from "./components/MembersAdmin/Index";
import { CompanyKey } from "./components/CompanyKey/Index";
import { API, graphqlOperation } from "aws-amplify";
import { getFundingCompany } from "../src/graphql/queries";
import { Auth, VerifyContact } from "aws-amplify";
import { Button } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

Amplify.configure(awsconfig);

const mdTheme = createTheme();

const MFATypes = {
  SMS: true,
  TOTP: true,
  Optional: true,
};

function App() {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const [fundingCompanyName, setFundingCompanyName] = useState([]);

  async function handleLogOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (err) {
      console.log("Error signing out: ", err);
    }
  }

  const logOutHandler = () => {
    handleLogOut().then();
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      if (
        authData &&
        authData.attributes &&
        nextAuthState === AuthState.SignedIn
      ) {
        localStorage.setItem(
          "userGroup",
          authData.signInUserSession.accessToken.payload["cognito:groups"]
        );
        localStorage.setItem(
          "companyId",
          authData.attributes["custom:companyId"]
        );
        localStorage.setItem(
          "userAttributes",
          JSON.stringify(authData.attributes)
        );
        localStorage.setItem("status", authData.attributes["custom:status"]);

        API.graphql(
          graphqlOperation(getFundingCompany, {
            id: authData.attributes["custom:companyId"],
          })
        )
          .then((result) => {
            setFundingCompanyName(result.data.getFundingCompany.name);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }, []);

  return authState === AuthState.SignedIn &&
    user &&
    localStorage.getItem("status") === "Active" ? (
    <Provider store={store}>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header user={user} fundingCompany={fundingCompanyName} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/fraudalerts" component={FraudAlert} />
                <Route exact path="/incidents" component={Incident} />
                <Route
                  exact
                  path="/uploadincidents"
                  component={UploadIncidents}
                />
                <Route exact path="/payoffs" component={ViewPayoff} />
                <Route
                  exact
                  path="/incidents/search"
                  component={SearchIncidents}
                />

                {/* Funding Company Admin Menu Options */}
                {localStorage.getItem("userGroup") === "admin" ||
                localStorage.getItem("userGroup") === "fcadmin" ? (
                  <Route exact path="/members" component={Users} />
                ) : (
                  <Route exact path="/" component={Home} />
                )}

                {/* Admin Menu Options */}
                {localStorage.getItem("userGroup") === "admin" ? (
                  <Route
                    exact
                    path="/fundingcompany"
                    component={FundingCompany}
                  />
                ) : (
                  <Route exact path="/" component={Home} />
                )}
                {localStorage.getItem("userGroup") === "admin" ? (
                  <Route exact path="/casetypes" component={CaseType} />
                ) : (
                  <Route exact path="/" component={Home} />
                )}
                {localStorage.getItem("userGroup") === "admin" ? (
                  <Route exact path="/states" component={States} />
                ) : (
                  <Route exact path="/" component={Home} />
                )}
                {localStorage.getItem("userGroup") === "admin" ? (
                  <Route exact path="/fcadmin" component={FCAdmin} />
                ) : (
                  <Route exact path="/" component={Home} />
                )}
                {localStorage.getItem("userGroup") === "admin" ? (
                  <Route exact path="/membersadmin" component={MembersAdmin} />
                ) : (
                  <Route exact path="/" component={Home} />
                )}
                {localStorage.getItem("userGroup") === "admin" ||
                localStorage.getItem("userGroup") === "fcadmin" ? (
                  <Route exact path="/companykey" component={CompanyKey} />
                ) : (
                  <Route exact path="/" component={Home} />
                )}

                <Redirect from="*" to="/" />
              </Switch>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </Provider>
  ) : (
    <div>
      {authState === AuthState.SignedIn ? (
        <div>
          <p>
            Your account is suspended. Please contact your company
            Administrator.
          </p>
          <Button
            style={{
              borderRadius: 20,
              backgroundColor: "#ff9900",
              padding: "10px 20px",
              fontSize: "10px",
            }}
            onClick={logOutHandler}
            variant="contained"
            endIcon={<ExitToAppIcon />}
            color="secondary"
          >
            Logout
          </Button>
        </div>
      ) : (
        <AmplifyAuthenticator>
          <AmplifySignIn
            slot="sign-in"
            hideSignUp
            headerText="Sign In To ALFA"
          ></AmplifySignIn>
        </AmplifyAuthenticator>
      )}
    </div>
  );
}

export default App;
