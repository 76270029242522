/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      abbreviation
      name
      geoValue
      riskColorCode
      statuteLink
      legislativeAction
      lr
      rateCap
      misc
      enactedLR
      createdAt
      updatedAt
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        abbreviation
        name
        geoValue
        riskColorCode
        statuteLink
        legislativeAction
        lr
        rateCap
        misc
        enactedLR
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      companyId
      userName
      firstName
      lastName
      email
      password
      phone
      status
      group
      addAlert
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        userName
        firstName
        lastName
        email
        password
        phone
        status
        group
        addAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFraudAlert = /* GraphQL */ `
  query GetFraudAlert($id: ID!) {
    getFraudAlert(id: $id) {
      id
      companyId
      userId
      partyType
      title
      firstName
      lastName
      email
      phone
      firm
      description
      fraudStatus
      caseCaption
      createdAt
      updatedAt
    }
  }
`;
export const listFraudAlerts = /* GraphQL */ `
  query ListFraudAlerts(
    $filter: ModelFraudAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFraudAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        userId
        partyType
        title
        firstName
        lastName
        email
        phone
        firm
        description
        fraudStatus
        caseCaption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCasetype = /* GraphQL */ `
  query GetCasetype($id: ID!) {
    getCasetype(id: $id) {
      id
      type
      createdAt
      updatedAt
    }
  }
`;
export const listCasetypes = /* GraphQL */ `
  query ListCasetypes(
    $filter: ModelCasetypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasetypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayoutIncident = /* GraphQL */ `
  query GetPayoutIncident($id: ID!) {
    getPayoutIncident(id: $id) {
      id
      companyId
      incidentId
      fromEmail
      toEmail
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const listPayoutIncidents = /* GraphQL */ `
  query ListPayoutIncidents(
    $filter: ModelPayoutIncidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayoutIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        incidentId
        fromEmail
        toEmail
        subject
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyKey = /* GraphQL */ `
  query GetCompanyKey($id: ID!) {
    getCompanyKey(id: $id) {
      id
      companyId
      keyId
      description
      expires
      reminderEmail
      isProd
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyKeys = /* GraphQL */ `
  query ListCompanyKeys(
    $filter: ModelCompanyKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        keyId
        description
        expires
        reminderEmail
        isProd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByName = /* GraphQL */ `
  query UserByName(
    $userName: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByName(
      userName: $userName
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        userName
        firstName
        lastName
        email
        password
        phone
        status
        group
        addAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByGroup = /* GraphQL */ `
  query UserByGroup(
    $group: String
    $userName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByGroup(
      group: $group
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        userName
        firstName
        lastName
        email
        password
        phone
        status
        group
        addAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByCompany = /* GraphQL */ `
  query UserByCompany(
    $companyId: String
    $userName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCompany(
      companyId: $companyId
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        userName
        firstName
        lastName
        email
        password
        phone
        status
        group
        addAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByCompanyGroup = /* GraphQL */ `
  query UserByCompanyGroup(
    $companyId: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCompanyGroup(
      companyId: $companyId
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        userName
        firstName
        lastName
        email
        password
        phone
        status
        group
        addAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keyByCompany = /* GraphQL */ `
  query KeyByCompany(
    $companyId: String
    $keyId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyByCompany(
      companyId: $companyId
      keyId: $keyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        keyId
        description
        expires
        reminderEmail
        isProd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCasetypes = /* GraphQL */ `
  query SearchCasetypes(
    $filter: SearchableCasetypeFilterInput
    $sort: SearchableCasetypeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCasetypes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPayoutIncidents = /* GraphQL */ `
  query SearchPayoutIncidents(
    $filter: SearchablePayoutIncidentFilterInput
    $sort: SearchablePayoutIncidentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPayoutIncidents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyId
        incidentId
        fromEmail
        toEmail
        subject
        body
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getFundingCompany = /* GraphQL */ `
  query GetFundingCompany($id: ID!) {
    getFundingCompany(id: $id) {
      id
      name
      status
      email
      allowedDomains
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFundingCompanys = /* GraphQL */ `
  query ListFundingCompanys(
    $filter: ModelFundingCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFundingCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        email
        allowedDomains
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIncident = /* GraphQL */ `
  query GetIncident($id: ID!) {
    getIncident(id: $id) {
      id
      companyId
      firstName
      lastName
      middleName
      birthDate
      clientState
      clientZip
      clientStatus
      incidentDate
      litigationState
      targetDefendant
      caseType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIncidents = /* GraphQL */ `
  query ListIncidents(
    $filter: ModelIncidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        firstName
        lastName
        middleName
        birthDate
        clientState
        clientZip
        clientStatus
        incidentDate
        litigationState
        targetDefendant
        caseType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const incidentByCompany = /* GraphQL */ `
  query IncidentByCompany(
    $companyId: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    incidentByCompany(
      companyId: $companyId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        firstName
        lastName
        middleName
        birthDate
        clientState
        clientZip
        clientStatus
        incidentDate
        litigationState
        targetDefendant
        caseType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchIncidents = /* GraphQL */ `
  query SearchIncidents(
    $filter: SearchableIncidentFilterInput
    $sort: SearchableIncidentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchIncidents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyId
        firstName
        lastName
        middleName
        birthDate
        clientState
        clientZip
        clientStatus
        incidentDate
        litigationState
        targetDefendant
        caseType
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
