import * as React from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import List from "@mui/material/List";
import ListIcon from "@mui/icons-material/List";

export default function UserMenu() {
  return (
    <React.Fragment>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/fraudalerts">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Possible Fraud Alerts" />
        </ListItem>
        <ListItem button component={Link} to="/incidents">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Our Incidents" />
        </ListItem>
        <ListItem button component={Link} to="/uploadincidents">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Upload Incidents" />
        </ListItem>
        <ListItem button component={Link} to="/payoffs">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="View Payoffs" />
        </ListItem>
        <ListItem button component={Link} to="/incidents/search">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Search All Incidents" />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
