import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Search } from "./Search";

function SearchIncidents({ match }) {
  const { path } = match;
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={path} component={Search} />
      </Switch>
    </BrowserRouter>
  );
}

export { SearchIncidents };
