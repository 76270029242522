import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  searchPayoutIncidents,
  getFundingCompany,
} from "../../graphql/queries";
import { Grid, Stack } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import Title from "../Title";
import renderCellExpand from "./renderCellExpand";
import moment from "moment";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";

function ViewPayoff({ match }) {
  const userCompanyId = localStorage.getItem("companyId");
  const userDetails = JSON.parse(localStorage.getItem("userAttributes"));
  const [fetching, setFetching] = useState(false);
  const [payoffsRequested, setPayoffsRequested] = useState([]);
  const [payoffsReceived, setPayoffsReceived] = useState([]);
  const [fundingCompany, setFundingCompany] = useState([]);

  useEffect(() => {
    API.graphql(graphqlOperation(getFundingCompany, { id: userCompanyId }))
      .then((result) => {
        setFundingCompany(result.data.getFundingCompany);
        fetchPayOffsReceived(result.data.getFundingCompany.email);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchPayOffsRequested();
  }, []);

  const requestedFilter = {
    fromEmail: { eq: userDetails.email },
  };

  const fetchPayOffsRequested = async () => {
    setFetching(true);
    try {
      const payoffData = await API.graphql(
        graphqlOperation(searchPayoutIncidents, { filter: requestedFilter })
      );
      const payoffList = payoffData.data.searchPayoutIncidents.items;
      setPayoffsRequested(payoffList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Payoffs Requested", error);
    }
    setFetching(false);
  };

  const fetchPayOffsReceived = async (toEmail) => {
    setFetching(true);
    try {
      const payoffData = await API.graphql(
        graphqlOperation(searchPayoutIncidents, {
          filter: {
            toEmail: { eq: toEmail },
          },
        })
      );
      const payoffList = payoffData.data.searchPayoutIncidents.items;
      setPayoffsReceived(payoffList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Payoffs Requested", error);
    }
    setFetching(false);
  };

  const requestedColumns = [
    {
      field: "createdAt",
      headerName: "Requested Date",
      sortable: true,
      flex: 1.5,
      renderCell: (params) => {
        return <>{moment(params.value).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      field: "toEmail",
      headerName: "Sent To",
      sortable: true,
      flex: 1,
    },
    {
      field: "subject",
      headerName: "Subject",
      sortable: false,
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "body",
      headerName: "Body",
      sortable: false,
      flex: 3,
      renderCell: renderCellExpand,
    },
  ];

  const receivedColumns = [
    {
      field: "createdAt",
      headerName: "Requested Date",
      sortable: true,
      flex: 1.5,
      renderCell: (params) => {
        return <>{moment(params.value).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      field: "fromEmail",
      headerName: "Received From",
      sortable: true,
      flex: 1,
    },
    {
      field: "subject",
      headerName: "Subject",
      sortable: false,
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "body",
      headerName: "Body",
      sortable: false,
      flex: 3,
      renderCell: renderCellExpand,
    },
  ];

  return (
    <React.Fragment>
      <Grid>
        <Stack direction="column" spacing={2}>
          <Title>Payoffs Requested</Title>
          {fetching ? (
            <p>Fetching Payoffs Requested...</p>
          ) : (
            <div style={{ height: 600, width: "100%" }}>
              <DataGridPro
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                rows={payoffsRequested}
                columns={requestedColumns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 30, 50]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
          <Title>Payoffs Received</Title>
          {fetching ? (
            <p>Fetching Payoffs Received...</p>
          ) : (
            <div style={{ height: 600, width: "100%" }}>
              <DataGridPro
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                rows={payoffsReceived}
                columns={receivedColumns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 30, 50]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

export { ViewPayoff };
