/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFundingCompany = /* GraphQL */ `
  mutation CreateFundingCompany(
    $input: CreateFundingCompanyInput!
    $condition: ModelFundingCompanyConditionInput
  ) {
    createFundingCompany(input: $input, condition: $condition) {
      id
      name
      status
      email
      allowedDomains
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFundingCompany = /* GraphQL */ `
  mutation UpdateFundingCompany(
    $input: UpdateFundingCompanyInput!
    $condition: ModelFundingCompanyConditionInput
  ) {
    updateFundingCompany(input: $input, condition: $condition) {
      id
      name
      status
      email
      allowedDomains
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFundingCompany = /* GraphQL */ `
  mutation DeleteFundingCompany(
    $input: DeleteFundingCompanyInput!
    $condition: ModelFundingCompanyConditionInput
  ) {
    deleteFundingCompany(input: $input, condition: $condition) {
      id
      name
      status
      email
      allowedDomains
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      abbreviation
      name
      geoValue
      riskColorCode
      statuteLink
      legislativeAction
      lr
      rateCap
      misc
      enactedLR
      createdAt
      updatedAt
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      abbreviation
      name
      geoValue
      riskColorCode
      statuteLink
      legislativeAction
      lr
      rateCap
      misc
      enactedLR
      createdAt
      updatedAt
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      abbreviation
      name
      geoValue
      riskColorCode
      statuteLink
      legislativeAction
      lr
      rateCap
      misc
      enactedLR
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      companyId
      userName
      firstName
      lastName
      email
      password
      phone
      status
      group
      addAlert
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      companyId
      userName
      firstName
      lastName
      email
      password
      phone
      status
      group
      addAlert
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      companyId
      userName
      firstName
      lastName
      email
      password
      phone
      status
      group
      addAlert
      createdAt
      updatedAt
    }
  }
`;
export const createIncident = /* GraphQL */ `
  mutation CreateIncident(
    $input: CreateIncidentInput!
    $condition: ModelIncidentConditionInput
  ) {
    createIncident(input: $input, condition: $condition) {
      id
      companyId
      firstName
      lastName
      middleName
      birthDate
      clientState
      clientZip
      clientStatus
      incidentDate
      litigationState
      targetDefendant
      caseType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIncident = /* GraphQL */ `
  mutation UpdateIncident(
    $input: UpdateIncidentInput!
    $condition: ModelIncidentConditionInput
  ) {
    updateIncident(input: $input, condition: $condition) {
      id
      companyId
      firstName
      lastName
      middleName
      birthDate
      clientState
      clientZip
      clientStatus
      incidentDate
      litigationState
      targetDefendant
      caseType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIncident = /* GraphQL */ `
  mutation DeleteIncident(
    $input: DeleteIncidentInput!
    $condition: ModelIncidentConditionInput
  ) {
    deleteIncident(input: $input, condition: $condition) {
      id
      companyId
      firstName
      lastName
      middleName
      birthDate
      clientState
      clientZip
      clientStatus
      incidentDate
      litigationState
      targetDefendant
      caseType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFraudAlert = /* GraphQL */ `
  mutation CreateFraudAlert(
    $input: CreateFraudAlertInput!
    $condition: ModelFraudAlertConditionInput
  ) {
    createFraudAlert(input: $input, condition: $condition) {
      id
      companyId
      userId
      partyType
      title
      firstName
      lastName
      email
      phone
      firm
      description
      fraudStatus
      caseCaption
      createdAt
      updatedAt
    }
  }
`;
export const updateFraudAlert = /* GraphQL */ `
  mutation UpdateFraudAlert(
    $input: UpdateFraudAlertInput!
    $condition: ModelFraudAlertConditionInput
  ) {
    updateFraudAlert(input: $input, condition: $condition) {
      id
      companyId
      userId
      partyType
      title
      firstName
      lastName
      email
      phone
      firm
      description
      fraudStatus
      caseCaption
      createdAt
      updatedAt
    }
  }
`;
export const deleteFraudAlert = /* GraphQL */ `
  mutation DeleteFraudAlert(
    $input: DeleteFraudAlertInput!
    $condition: ModelFraudAlertConditionInput
  ) {
    deleteFraudAlert(input: $input, condition: $condition) {
      id
      companyId
      userId
      partyType
      title
      firstName
      lastName
      email
      phone
      firm
      description
      fraudStatus
      caseCaption
      createdAt
      updatedAt
    }
  }
`;
export const createCasetype = /* GraphQL */ `
  mutation CreateCasetype(
    $input: CreateCasetypeInput!
    $condition: ModelCasetypeConditionInput
  ) {
    createCasetype(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateCasetype = /* GraphQL */ `
  mutation UpdateCasetype(
    $input: UpdateCasetypeInput!
    $condition: ModelCasetypeConditionInput
  ) {
    updateCasetype(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteCasetype = /* GraphQL */ `
  mutation DeleteCasetype(
    $input: DeleteCasetypeInput!
    $condition: ModelCasetypeConditionInput
  ) {
    deleteCasetype(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
    }
  }
`;
export const createPayoutIncident = /* GraphQL */ `
  mutation CreatePayoutIncident(
    $input: CreatePayoutIncidentInput!
    $condition: ModelPayoutIncidentConditionInput
  ) {
    createPayoutIncident(input: $input, condition: $condition) {
      id
      companyId
      incidentId
      fromEmail
      toEmail
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const updatePayoutIncident = /* GraphQL */ `
  mutation UpdatePayoutIncident(
    $input: UpdatePayoutIncidentInput!
    $condition: ModelPayoutIncidentConditionInput
  ) {
    updatePayoutIncident(input: $input, condition: $condition) {
      id
      companyId
      incidentId
      fromEmail
      toEmail
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const deletePayoutIncident = /* GraphQL */ `
  mutation DeletePayoutIncident(
    $input: DeletePayoutIncidentInput!
    $condition: ModelPayoutIncidentConditionInput
  ) {
    deletePayoutIncident(input: $input, condition: $condition) {
      id
      companyId
      incidentId
      fromEmail
      toEmail
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyKey = /* GraphQL */ `
  mutation CreateCompanyKey(
    $input: CreateCompanyKeyInput!
    $condition: ModelCompanyKeyConditionInput
  ) {
    createCompanyKey(input: $input, condition: $condition) {
      id
      companyId
      keyId
      description
      expires
      reminderEmail
      isProd
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyKey = /* GraphQL */ `
  mutation UpdateCompanyKey(
    $input: UpdateCompanyKeyInput!
    $condition: ModelCompanyKeyConditionInput
  ) {
    updateCompanyKey(input: $input, condition: $condition) {
      id
      companyId
      keyId
      description
      expires
      reminderEmail
      isProd
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyKey = /* GraphQL */ `
  mutation DeleteCompanyKey(
    $input: DeleteCompanyKeyInput!
    $condition: ModelCompanyKeyConditionInput
  ) {
    deleteCompanyKey(input: $input, condition: $condition) {
      id
      companyId
      keyId
      description
      expires
      reminderEmail
      isProd
      createdAt
      updatedAt
    }
  }
`;
