import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { createFraudAlert, updateFraudAlert } from "../../graphql/mutations";
import { getFraudAlert } from "../../graphql/queries";
import { listFundingCompanys } from "../../graphql/queries";
import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Title from "../Title";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PhoneNumberInput } from "../Utilities/PhoneNumberInput";

function AddEdit({ history, match }) {
  const userCompanyId = localStorage.getItem("companyId");
  const userDetails = JSON.parse(localStorage.getItem("userAttributes"));
  const addAlert = userDetails["custom:addAlert"];
  let addAlertAllowed = addAlert.toLowerCase() === "true" ? true : false;
  const [fetching, setFetching] = useState(false);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const title = watch("title", "");
  const fraudStatus = watch("fraudStatus", "");
  const partyType = watch("partyType", "");
  const companyId = watch("companyId", "");

  const { id } = match.params;
  const isAddMode = !id;

  const fetchFundingCompanies = async () => {
    setFetching(true);
    try {
      const fundingCompanyData = await API.graphql(
        graphqlOperation(listFundingCompanys)
      );
      const fundingCompanyList =
        fundingCompanyData.data.listFundingCompanys.items;
      setFundingCompanies(fundingCompanyList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Funding Comapnies", error);
    }
    setFetching(false);
  };
  useEffect(() => {
    fetchFundingCompanies();
  }, []);

  useEffect(() => {
    const fetchFraudAlert = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getFraudAlert, { id: id })
        );
        const fraudAlertData = result.data.getFraudAlert;
        [
          "companyId",
          "userId",
          "partyType",
          "title",
          "firstName",
          "lastName",
          "email",
          "phone",
          "firm",
          "description",
          "fraudStatus",
          "caseCaption",
        ].forEach((fieldName) => {
          setValue(fieldName, fraudAlertData[fieldName]);
        });
      } catch (error) {
        console.log("Error Fetching Fraud Alerts", error);
      }
    };
    if (id) {
      fetchFraudAlert();
    } else {
      setValue("companyId", userCompanyId);
      setValue("userId", userDetails.email);
    }
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      data.id = id;
      await API.graphql({
        query: updateFraudAlert,
        variables: { input: data },
      });
    } else {
      await API.graphql({
        query: createFraudAlert,
        variables: { input: data },
      });
    }
    window.location.replace("/fraudalerts");
  };

  return (
    <React.Fragment>
      <Title>
        {isAddMode ? "Add Possible Fraud Alert" : "Edit Possible Fraud Alert"}
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {isAddMode ? (
              <FormControl fullWidth>
                <InputLabel id="companyId">Funding Company</InputLabel>
                <Select
                  input={<Input id="companyId" />}
                  disabled
                  id="companyId"
                  value={userCompanyId}
                  label="Funding Company"
                  error={errors.companyId}
                >
                  {fundingCompanies.map((fc) => (
                    <MenuItem key={fc.id} value={fc.id}>
                      {fc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <InputLabel id="companyId">Funding Company</InputLabel>
                <Select
                  input={<Input id="companyId" />}
                  disabled
                  id="companyId"
                  value={companyId}
                  label="Funding Company"
                  error={errors.companyId}
                >
                  {fundingCompanies.map((fc) => (
                    <MenuItem key={fc.id} value={fc.id}>
                      {fc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="userId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled
                  id="userId"
                  name="userId"
                  label="Alert Creator"
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  error={errors.userId}
                  {...register("userId", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="fraudStatus">Status*</InputLabel>
              <Select
                input={<Input id="fraudStatus" />}
                labelId="fraudStatus"
                required
                id="fraudStatus"
                value={fraudStatus}
                label="Status"
                error={errors.fraudStatus}
                {...register("fraudStatus", { required: true })}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="Party First Name"
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  error={errors.firstName}
                  {...register("firstName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Party Last Name"
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  error={errors.lastName}
                  {...register("lastName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="partyType">Party Type*</InputLabel>
              <Select
                input={<Input id="partyType" />}
                required
                id="partyType"
                value={partyType}
                label="Party Type"
                error={errors.partyType}
                {...register("partyType", { required: true })}
              >
                <MenuItem value="Case">Case</MenuItem>
                <MenuItem value="Client">Client</MenuItem>
                <MenuItem value="Broker">Broker</MenuItem>
                <MenuItem value="Law Firm">Law Firm</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="email"
                  name="email"
                  label="Party Email"
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  error={errors.email}
                  {...register("email", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  value={value}
                  onChange={onChange}
                  defaultCountry="US"
                  id="phone"
                  inputComponent={PhoneNumberInput}
                />
              )}
            />
            {errors["phone"] && (
              <p className="error-message">Invalid Phone Number</p>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="firm"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="firm"
                  name="firm"
                  label="Party Firm"
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  disabled={watch("partyType") === "Client"}
                  error={errors.firm}
                  {...register("firm", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="title">Party Title</InputLabel>
              <Select
                input={<Input id="title" />}
                id="title"
                value={title}
                label="Party Title"
                error={errors.title}
                disabled={watch("partyType") === "Client"}
                {...register("title", { required: false })}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Attorney">Attorney</MenuItem>
                <MenuItem value="Paralegal">Paralegal</MenuItem>
                <MenuItem value="Receptionist">Receptionist</MenuItem>
                <MenuItem value="Office Manager">Office Manager</MenuItem>
                <MenuItem value="Secretary">Secretary</MenuItem>
                <MenuItem value="Broker">Broker</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="caseCaption"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="caseCaption"
                  name="caseCaption"
                  label="Case Caption"
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  error={errors.caseCaption}
                  {...register("caseCaption", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  value={value || ""}
                  variant="standard"
                  error={errors.description}
                  {...register("description", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item container justifyContent="center" alignItems="center">
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                {/* {addAlert ? "Not Allowed" : isAddMode ? "Create" : "Update"} */}
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
