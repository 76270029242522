import React, { useState, useEffect } from "react";
import MapChart from "./MapChart";
import { Storage } from "aws-amplify";
import { Link, List, ListItem, Grid, Modal, Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Home() {
  const [s3files, setS3Files] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchAllFiles();
  }, []);

  async function fetchAllFiles() {
    const files = await Storage.list("legal-documents/");
    const fileList = [];
    const signedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.size) {
          // let possibleFolder = file.key.split("/").slice(0, -1).join("/");
          const signedFile = await Storage.get(file.key, { expires: 60 });
          fileList.push({
            url: signedFile,
            fileName: file.key.split("/")[1],
          });
        }
      })
    );
    setS3Files(fileList);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={10}></Grid>
      <Grid item xs={12} md={2}>
        <Button onClick={handleOpen}>Legal Documents</Button>
        <Modal open={open} onClose={handleClose}>
          <div>
            <Box sx={style} padding={3}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Legal Documents
              </Typography>
              {s3files.map((file) => (
                <List>
                  <ListItem disablePadding>
                    <Link target="_blank" rel="noopener" href={file.url}>
                      {file.fileName}
                    </Link>
                  </ListItem>
                </List>
              ))}
            </Box>
          </div>
        </Modal>
      </Grid>
      <Grid item xs={12} md={12}>
        <MapChart />
      </Grid>
    </Grid>
  );
}

export { Home };
