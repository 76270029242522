import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { createIncident, updateIncident } from "../../graphql/mutations";
import {
  listStates,
  getIncident,
  searchCasetypes,
} from "../../graphql/queries";
import { listFundingCompanys, searchIncidents } from "../../graphql/queries";
import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Title from "../Title";
import moment from "moment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

function AddEdit({ history, match }) {
  const userCompanyId = localStorage.getItem("companyId");
  const [states, setStates] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [caseTypes, setCaseTypes] = useState([]);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());
  const [incidentDate, setIncidentDate] = useState(new Date());

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const litigationState = watch("litigationState", "");
  const companyId = watch("companyId", "");
  const clientState = watch("clientState", "");
  const clientStatus = watch("clientStatus", "");
  const caseType = watch("caseType", "");

  const { id } = match.params;
  const isAddMode = !id;

  useEffect(() => {
    const fetchIncident = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getIncident, { id: id })
        );
        const incidentData = result.data.getIncident;
        [
          "companyId",
          "firstName",
          "lastName",
          "middleName",
          "userId",
          "partyType",
          "title",
          "litigationState",
          "clientState",
          "caseType",
          "clientStatus",
          "clientZip",
          "targetDefendant",
          "birthDate",
          "incidentDate",
        ].forEach((fieldName) => {
          setValue(fieldName, incidentData[fieldName]);
        });
      } catch (error) {
        console.log("Error Fetching Incidents", error);
      }
    };
    if (id) {
      fetchIncident();
    } else {
      setValue("companyId", userCompanyId);
    }
  }, [id]);

  const birthDateValue = getValues("birthDate");

  useEffect(() => {
    setBirthDate(birthDateValue || null);
  }, [setBirthDate, birthDateValue]);

  const incidentDateValue = getValues("incidentDate");

  useEffect(() => {
    setIncidentDate(incidentDateValue || null);
  }, [setIncidentDate, incidentDateValue]);

  const onSubmit = async (data) => {
    console.log(data);
    data.birthDate = moment(birthDate).format("MM/DD/YYYY");
    data.incidentDate = moment(incidentDate).format("MM/DD/YYYY");

    const filter = {
      companyId: { eq: data.companyId },
      firstName: { eq: data.firstName },
      lastName: { eq: data.lastName },
      birthDate: { eq: data.birthDate },
      incidentDate: { eq: data.incidentDate },
      caseType: { eq: data.caseType },
    };

    const searchData = await API.graphql(
      graphqlOperation(searchIncidents, { filter })
    );

    const searchList = searchData.data.searchIncidents.items;
    console.log("searchList", searchList);

    if (searchList.length === 0) {
      if (id) {
        data.id = id;
        await API.graphql({
          query: updateIncident,
          variables: { input: data },
        });
      } else {
        await API.graphql({
          query: createIncident,
          variables: { input: data },
        });
      }
    } else {
      if (id) {
        data.id = id;
        await API.graphql({
          query: updateIncident,
          variables: { input: data },
        });
      } else if (searchList.length === 1) {
        data.id = searchList[0].id;
        await API.graphql({
          query: updateIncident,
          variables: { input: data },
        });
      } else {
        alert(
          "Failed to Create/Update Incident. Please reach out to Administrator."
        );
      }
    }

    // if (id) {
    //   data.id = id;
    //   await API.graphql({
    //     query: updateIncident,
    //     variables: { input: data },
    //   });
    // } else {
    //   await API.graphql({
    //     query: createIncident,
    //     variables: { input: data },
    //   });
    // }
    window.location.replace("/incidents");
  };

  useEffect(() => {
    fetchFundingCompanies();
    fetchStates();
    fetchCaseTypes();
  }, []);

  const fetchFundingCompanies = async () => {
    setFetching(true);
    try {
      const fundingCompanyData = await API.graphql(
        graphqlOperation(listFundingCompanys)
      );
      const fundingCompanyList =
        fundingCompanyData.data.listFundingCompanys.items;
      setFundingCompanies(fundingCompanyList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Funding Companies", error);
    }
    setFetching(false);
  };

  const fetchStates = async () => {
    setFetching(true);
    try {
      const statesData = await API.graphql(graphqlOperation(listStates));
      const statesList = statesData.data.listStates.items;
      const statesSorted = statesList.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      setStates(statesSorted);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching States", error);
    }
    setFetching(false);
  };

  const fetchCaseTypes = async () => {
    setFetching(true);
    try {
      const caseTypeData = await API.graphql(
        graphqlOperation(searchCasetypes, {
          sort: {
            field: "type",
            direction: "asc",
          },
        })
      );
      const caseTypeList = caseTypeData.data.searchCasetypes.items;
      setCaseTypes(caseTypeList);
      setFetching(false);
    } catch (error) {
      console.log("Error Fetching Case Types", error);
    }
    setFetching(false);
  };

  return (
    <React.Fragment>
      <Title>{isAddMode ? "Add Incident" : "Edit Incident"}</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {isAddMode ? (
              <FormControl fullWidth>
                <InputLabel id="companyId">Funding Company</InputLabel>
                <Select
                  input={<Input id="companyId" />}
                  disabled
                  id="companyId"
                  value={userCompanyId}
                  label="Funding Company"
                  error={errors.companyId}
                >
                  {fundingCompanies.map((fc) => (
                    <MenuItem key={fc.id} value={fc.id}>
                      {fc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <InputLabel id="companyId">Funding Company</InputLabel>
                <Select
                  input={<Input id="companyId" />}
                  disabled
                  id="companyId"
                  value={companyId}
                  label="Funding Company"
                  error={errors.companyId}
                >
                  {fundingCompanies.map((fc) => (
                    <MenuItem key={fc.id} value={fc.id}>
                      {fc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="clientStatus">Client Status*</InputLabel>
              <Select
                input={<Input id="clientStatus" />}
                labelId="clientStatus"
                required
                id="clientStatus"
                value={clientStatus}
                label="Status"
                error={errors.clientStatus}
                {...register("clientStatus", { required: true })}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="Paid-Off Attorney">Paid-Off Attorney</MenuItem>
                <MenuItem value="Paid-Off Funder">Paid-Off Funder</MenuItem>
                <MenuItem value="Write-Off">Write-Off</MenuItem>
                <MenuItem value="Duplicate">Duplicate</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="case-type">Case Type*</InputLabel>
              <Select
                input={<Input id="caseType" />}
                labelId="caseType"
                required
                id="caseType"
                value={caseType}
                label="Case Type"
                error={errors.caseType}
                {...register("caseType", { required: true })}
              >
                {caseTypes.map((caseType) => (
                  <MenuItem key={caseType.id} value={caseType.type}>
                    {caseType.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="Client First Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.firstName}
                  {...register("firstName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="middleName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="middleName"
                  name="middleName"
                  label="Client Middle Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.middleName}
                  {...register("middleName", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Client Last Name"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.lastName}
                  {...register("lastName", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                autoOk
                label="Birth Date"
                inputFormat="MM/dd/yyyy"
                maxDate={new Date()}
                value={birthDate && Date.parse(birthDate)}
                onChange={(date) => setBirthDate(date)}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="client-state">Client State*</InputLabel>
              <Select
                input={<Input id="clientState" />}
                required
                id="clientState"
                value={clientState}
                label="Client State"
                error={errors.clientState}
                {...register("clientState", { required: true })}
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="clientZip"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="clientZip"
                  name="clientZip"
                  label="Client Zip Code"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.clientZip}
                  {...register("clientZip", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                autoOk
                label="Incident Date"
                inputFormat="MM/dd/yyyy"
                maxDate={new Date()}
                value={incidentDate && Date.parse(incidentDate)}
                onChange={(date) => setIncidentDate(date)}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="litigation-state">
                Litigation State*
              </InputLabel>
              <Select
                input={<Input id="litigationState" />}
                required
                id="litigationState"
                value={litigationState}
                label="Litigation State"
                error={errors.litigationState}
                {...register("litigationState", { required: true })}
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="targetDefendant"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="targetDefendant"
                  name="targetDefendant"
                  label="Target Defendant"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.targetDefendant}
                  {...register("targetDefendant", { required: false })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item container justifyContent="center" alignItems="center">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Button type="submit" color="primary" variant="contained">
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
