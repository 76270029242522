import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { List } from "./List";
import { AddEdit } from "./AddEdit";

function FCAdmin ({ match }) {
  const { path } = match;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={AddEdit} />
        <Route path={`${path}/edit/:id`} component={AddEdit} />
      </Switch>
    </BrowserRouter>
  );
}

export { FCAdmin };
