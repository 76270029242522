import React, { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createCasetype, updateCasetype } from "../../graphql/mutations";
import { getCasetype } from "../../graphql/queries";
import { Grid, TextField } from "@mui/material";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Title from "../Title";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";

function AddEdit({ history, match }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm();

  const { id } = match.params;
  const isAddMode = !id;

  useEffect(() => {
    const fetchCaseType = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getCasetype, { id: id })
        );
        const caseTypeData = result.data.getCasetype;
        ["type"].forEach((fieldName) => {
          setValue(fieldName, caseTypeData[fieldName]);
        });
      } catch (error) {
        console.log("Error Fetching Case Types", error);
      }
    };
    if (id) {
      fetchCaseType();
    }
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      data.id = id;
      await API.graphql({
        query: updateCasetype,
        variables: { input: data },
      });
    } else {
      await API.graphql({
        query: createCasetype,
        variables: { input: data },
      });
    }
    window.location.replace("/casetypes");
  };

  return (
    <React.Fragment>
      <Title>
        {isAddMode ? "Add Incident Case Type<" : "Edit Incident Case Type"}
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="type"
                  name="type"
                  label="Incident Case Type"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.type}
                  {...register("type", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
