import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Title from "../Title";
import { createCompanyKey, updateCompanyKey } from "../../graphql/mutations";
import { getCompanyKey } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

const useStyles = makeStyles((theme) => ({}));

function AddEdit({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const userCompanyId = localStorage.getItem("companyId");
  const isProd = watch("isProd", "");

  useEffect(() => {
    const fetchCompanyKey = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getCompanyKey, { id: id })
        );
        const companyKeyData = result.data.getCompanyKey;
        ["companyId", "keyId", "description", "reminderEmail"].forEach(
          (fieldName) => {
            setValue(fieldName, companyKeyData[fieldName]);
          }
        );
      } catch (error) {
        console.log("Error Fetching Company Keys", error);
      }
    };
    if (id) {
      fetchCompanyKey();
    }
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      data.id = id;
      data.companyId = userCompanyId;
      await API.graphql({
        query: updateCompanyKey,
        variables: { input: data },
      });
    } else {
      data.companyId = userCompanyId;
      await API.graphql({
        query: createCompanyKey,
        variables: { input: data },
      });
    }
    window.location.replace("/companykey");
  };

  return (
    <React.Fragment>
      <Title>{isAddMode ? "Add Company Key" : "Edit Company Key"}</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="description"
                  name="description"
                  label="Key Description"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.name}
                  {...register("description", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="reminderEmail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  id="reminderEmail"
                  name="reminderEmail"
                  label="Reminder Email"
                  fullWidth
                  variant="standard"
                  value={value || ""}
                  error={errors.name}
                  {...register("reminderEmail", { required: true })}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <InputLabel id="isProd">Is Prod?</InputLabel>
              <Select
                input={<Input id="isProd" />}
                labelId="isProd"
                required
                id="isProd"
                value={isProd}
                label="Is Prod?"
                error={errors.isProd}
                {...register("isProd", { required: true })}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                {isAddMode ? "Create" : "Update"}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={isAddMode ? "." : ".."}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export { AddEdit };
