import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listFraudAlerts, listFundingCompanys } from "../../graphql/queries";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import Title from "../Title";
import { Button } from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../Utilities/CustomNoRowsOverlay";
import PreviewIcon from "@mui/icons-material/Preview";
import { useForm, Controller } from "react-hook-form";
import {
  FormControl,
  Grid,
  TextField,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PhoneNumberInput } from "../Utilities/PhoneNumberInput";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function List({ match }) {
  const { path } = match;
  const history = useHistory();
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const userCompanyId = localStorage.getItem("companyId");
  const userDetails = JSON.parse(localStorage.getItem("userAttributes"));
  const addAlert = userDetails["custom:addAlert"];
  let addAlertAllowed = addAlert.toLowerCase() === "true" ? true : false;
  // const isBoolean = (val) => "boolean" === typeof val;
  // console.log(isBoolean(addAlertAllowed));
  const [fraudAlerts, setFraudAlerts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      API.graphql(graphqlOperation(listFundingCompanys))
        .then((result) => {
          fetchFraudAlerts();
          setFundingCompanies(result.data.listFundingCompanys.items);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const filter = {
    companyId: { eq: userCompanyId },
  };

  const fetchFraudAlerts = async () => {
    setFetching(true);
    try {
      const fraudAlertData = await API.graphql(
        graphqlOperation(listFraudAlerts)
      );
      const fraudAlertList = fraudAlertData.data.listFraudAlerts.items;
      const fraudAlertFilteredList = fraudAlertList.filter(
        (attr) =>
          attr.fraudStatus === "Active" ||
          (attr.fraudStatus === "Inactive" && attr.userId === userDetails.email)
      );
      setFraudAlerts(fraudAlertFilteredList);
      setFetching(false);
    } catch (error) {
      console.log("Error fetching Fraud Alerts", error);
    }
    setFetching(false);
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    if (addAlertAllowed) {
      const currentFraudAlert = fraudAlerts.find((x) => x.id === id);
      if (currentFraudAlert.userId === userDetails.email) {
        history.push(`/fraudalerts/edit/${id}`);
      }
    } else {
      setAlertOpen(true);
    }
  };

  const handlePreviewClick = (id) => (event) => {
    event.stopPropagation();
    const currentFraudAlert = fraudAlerts.find((x) => x.id === id);
    const fraudAlertData = currentFraudAlert;
    [
      "companyId",
      "firstName",
      "lastName",
      "userId",
      "partyType",
      "title",
      "email",
      "phone",
      "firm",
      "description",
      "fraudStatus",
      "caseCaption",
      "firm",
    ].forEach((fieldName) => {
      setValue(fieldName, fraudAlertData[fieldName]);
    });
    handleOpen();
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      disableExport: true,
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<PreviewIcon />}
            label="Preview"
            onClick={handlePreviewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "companyId",
      headerName: "Funding Company",
      flex: 1.5,
      editable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div>
            {fundingCompanies.find((fc) => fc.id === params.value).name}
          </div>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "partyType",
      headerName: "Party Type",
      sortable: true,
      flex: 1,
    },
    {
      field: "firm",
      headerName: "Party Firm",
      sortable: true,
      flex: 1,
    },
    {
      field: "fraudStatus",
      headerName: "Fraud Status",
      sortable: true,
      flex: 1,
    },
  ];

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Collapse in={alertOpen}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            You do not have permission to edit this alert.
          </Alert>
        </Collapse>
      </Box>
      {addAlertAllowed ? (
        <Button
          variant="contained"
          component={Link}
          to={`${path}/add`}
          color="primary"
        >
          Add Possible Fraud Alert
        </Button>
      ) : (
        ""
      )}
      <Title>Possible Fraud Alerts</Title>
      {fetching ? (
        <p>Fetching Possible Fraud Alerts...</p>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPro
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={fraudAlerts}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} padding={3}>
          <FormControl
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ margin: "20px" }}
            >
              View Possible Fraud Alert
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="userId"
                      name="userId"
                      label="Alert Creator"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.userId}
                      {...register("userId", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="fraudStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="fraudStatus"
                      name="fraudStatus"
                      label="Fraud Status"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.fraudStatus}
                      {...register("fraudStatus", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="firstName"
                      name="firstName"
                      label="Party First Name"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.firstName}
                      {...register("firstName", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="lastName"
                      name="lastName"
                      label="Party Last Name"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.lastName}
                      {...register("lastName", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partyType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="partyType"
                      name="partyType"
                      label="Party Type"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.partyType}
                      {...register("partyType", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="email"
                      name="email"
                      label="Party Email"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.email}
                      {...register("email", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      disabled
                      value={value}
                      onChange={onChange}
                      defaultCountry="US"
                      id="phone"
                      inputComponent={PhoneNumberInput}
                    />
                  )}
                />
                {errors["phone"] && (
                  <p className="error-message">Invalid Phone Number</p>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firm"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="firm"
                      name="firm"
                      label="Party Firm"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.firm}
                      {...register("firm", { required: false })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="title"
                      name="title"
                      label="Party Title"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.title}
                      {...register("title", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="caseCaption"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="caseCaption"
                      name="caseCaption"
                      label="Case Caption"
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.caseCaption}
                      {...register("caseCaption", { required: false })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      id="description"
                      name="description"
                      label="Description"
                      multiline
                      rows={4}
                      fullWidth
                      value={value || ""}
                      variant="standard"
                      error={errors.description}
                      {...register("description", { required: true })}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export { List };
